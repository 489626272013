import styled from 'styled-components'
import { Icon } from '../icon/icon'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { irem } from '../../utils'

export const ProductCardTable = styled(Table)`
  margin: 0;
`

export const ProductCardTableBody = styled(TableBody)`
  margin: 0;
`

export const ProductCardTableRow = styled(TableRow)`
  ${props => props.theme.breakpoints.up('sm')} {
    height: ${props => props.theme.typography.pxToRem(100)};
  }

  > th,
  td {
    font-size: ${irem(17)};
    line-height: ${irem(18)};
    color: ${props => props.theme.palette.text.secondary};
    padding: ${irem(10)} ${irem(16)};

    ${props => props.theme.breakpoints.up('sm')} {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  td {
    background: #f2f2f2;
  }
`

export const ProductTableValueCell = styled(TableCell)`
  text-align: center;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.palette.background.default};
`
export const ProductTableKeyCell = styled(TableCell)`
  padding-left: ${props => props.theme.typography.pxToRem(4)};
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  background-color: white;
`

export const ProductCardTableIcon = styled(Icon)`
  font-size: ${props => props.theme.typography.pxToRem(18)};
  color: #d83b00;
`

export const ProductCardTableText = styled.p`
  margin: 0;
  color: ${props => props.theme.palette.text.secondary};
  font-size: ${props => props.theme.typography.pxToRem(17)};
`
