import { gql } from '@apollo/client'
import { Container } from '@material-ui/core'
import dynamic from 'next/dynamic'
import React, { PropsWithChildren, createContext, useMemo } from 'react'
import { Breadcrumb, Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs'
import { LazyComponent } from '../../components/lazyComponent/lazyComponent'
import {
  DisclaimerContext,
  DisclaimerIconMap,
  DisclaimerType,
} from '../../context/disclaimerContext'
import { isNotNull, nonNull } from '../../utils/nonNull'
import { iconObj } from '../common/disclaimerIcons'
import { PageQuery } from '../page/__generated__'
import {
  CAROUSEL_FRAGMENT,
  DISCLAIMER_FRAGMENT,
  FAQ_FRAGMENT,
  INFO_LINKS_FRAGMENT,
  LINKS_CTA_FRAGMENT,
  PRODUCT_COMPARISON_FRAGMENT,
  QUICK_LINKS_FRAGMENT,
  QuickLinks,
  TABS_FRAGMENT,
  TWO_COL_CTA_FRAGMENT,
} from './fragments'
import { PRODUCT_LISTING_BANNER_FRAGMENT, ProductBanner } from './fragments/secondaryBanner'
import { SlantedTopGreyBackground, StyledQuickLinksWrapper } from './styledProductListing'
import { CALL_OUT_FRAGMENT, CallOut } from './fragments/callOut'
import { ProductCallOutFragment } from './fragments/__generated__/callOut'
import { COMPARISON_CTA_CARD_FRAGMENT, COMPARISON_CTA_TITLE_FRAGMENT } from "./fragments/comparsionCTA";

export type PageProps = PageQuery

export const ProdPageContext = createContext<{prodPageId:string}>({
  prodPageId:'',
})

export const isTopComponentContext = createContext<{isTopComponent:boolean}>({
  isTopComponent:false,
})

export const isComparisonCtaContext = createContext<{isComparisonCta:boolean}>({
  isComparisonCta:false,
})

const validateColumns = (objectsArray: ProductCallOutFragment[]): boolean => {
  for (const obj of objectsArray) {
    if (obj.columnTitle === null) return false
    if (obj.columnContent === null) return false
  }

  return true
}

const TopContent = dynamic(() => import('./fragments/topContent').then(mod => mod.TopContent))
const BelowTheFold = dynamic(() => import('./fragments/belowTheFold').then(mod => mod.BelowTheFold))

export const ProductListing: React.FC<PropsWithChildren<PageProps>> = (data) => {
  if (!data.entry || data.entry.__typename !== 'products_pages_product_listing_Entry') {
    return null
  }

  const { entry } = data
  const { id, secondaryPageBanner, quickLinksProducts, productComparisonTableHomeLoan, parent, uri, title, columnCallOutTitle, columnCallOutCards, comparisonCtaPicker } = entry

  const banner = secondaryPageBanner?.[0]
  const quickLinks = quickLinksProducts?.[0]

  const prodPageId = useMemo(() => {
    return id as string
  }, [id])

  const isTopComponent = useMemo(() => {
    return isNotNull(quickLinks)
  }, [quickLinks])

  const isComparisonCta = useMemo(() => {
    return isNotNull(comparisonCtaPicker?.[0])
  }, [comparisonCtaPicker])

  const breadcrumbs = (
      <Breadcrumbs>
        <Breadcrumb href={'/'} home text={'Home'} />
        {parent?.parent && (
            <Breadcrumb href={`/${parent.parent.uri}`} text={parent.parent.title || ''} />
        )}
        {parent && (
            <Breadcrumb href={`/${parent.uri}`} text={parent.title || ''} />
        )}
        <Breadcrumb href={`/${uri}`} text={title || ''} />
      </Breadcrumbs>
  )

  const pageId = useMemo(() => {
    return id as string
  }, [id])

  const disclaimerArray = useMemo(() => {
    const sortedDisclaimerArray:DisclaimerType[] = []

    productComparisonTableHomeLoan?.map((rates, _) => {
      rates?.__typename === 'productComparisonTableHomeLoan_BlockType' &&
      rates.productRate?.map((rate, _) => {
        if ( rate?.__typename === 'rates_default_Entry') {
          if ( rate.homeLoanDisclaimers && rate.homeLoanDisclaimers?.length > 0 && rate?.homeLoanDisclaimers?.[0]?.__typename === 'homeLoanDisclaimers_default_Entry' ) {
            nonNull(rate.homeLoanDisclaimers).map((item, _) => {
              if (item && item?.__typename === 'homeLoanDisclaimers_default_Entry') {
                if (sortedDisclaimerArray.some(sortedDisclaimerArray => sortedDisclaimerArray.disclaimerId === item.disclaimerId)) {
                  return
                } else {
                  sortedDisclaimerArray.push(item as DisclaimerType)
                }
              }

              return null
            })
          }
          else if ( rate.personalLoanDisclaimers && rate.personalLoanDisclaimers?.length > 0 && rate?.personalLoanDisclaimers?.[0]?.__typename === 'personalloandisclaimers_default_Entry' ) {
            nonNull(rate.personalLoanDisclaimers).map((item, _) => {
              if (item && item?.__typename === 'personalloandisclaimers_default_Entry') {
                if (sortedDisclaimerArray.some(sortedDisclaimerArray => sortedDisclaimerArray.disclaimerId === item.disclaimerId)) {
                  return
                } else {
                  sortedDisclaimerArray.push(item as DisclaimerType)
                }
              }

              return null
            })

            return null
          }

          return null
        }})
    })

    return sortedDisclaimerArray
  }, [productComparisonTableHomeLoan])

  const iconMap: DisclaimerIconMap = useMemo(() => {
    const iconMap = new Map()

    for (const [i, disclaimer] of disclaimerArray.entries()) {
      iconMap.set(disclaimer.disclaimerId, <>{iconObj[i] ? iconObj[i] : `~${i}`}</>)
    }

    return iconMap
  }, [disclaimerArray])

  return (
      <>
        <DisclaimerContext.Provider value={{ disclaimerArray, iconMap, pageId }}>
          <ProdPageContext.Provider value={{ prodPageId }}>
            {banner && (
                <ProductBanner data={banner} breadcrumbs={breadcrumbs} { ...banner }  />
            )}
            <isComparisonCtaContext.Provider value={{ isComparisonCta }}>
              <SlantedTopGreyBackground $isComparisonCtaComponent={isComparisonCta} >
              <Container>
                {quickLinks && (
                    <StyledQuickLinksWrapper container item lg={10}
                                             direction="column"
                                             justifyContent="flex-start"
                                             alignItems="flex-start"
                                             wrap="nowrap"
                    >
                      <QuickLinks data={nonNull(quickLinksProducts)} />
                    </StyledQuickLinksWrapper>
                )}
                {/* @ts-ignore */}
                {isNotNull(columnCallOutCards) && validateColumns(columnCallOutCards) && (
                    <>
                      {/* @ts-ignore */}
                      <CallOut title={columnCallOutTitle} cards={nonNull(columnCallOutCards)} />
                    </>
                )}
                <LazyComponent>
                  <isTopComponentContext.Provider value={{ isTopComponent }}>
                    <TopContent { ...data } />
                  </isTopComponentContext.Provider>
                </LazyComponent>
              </Container>
            </SlantedTopGreyBackground>
            </isComparisonCtaContext.Provider>
            <LazyComponent>
              <BelowTheFold { ...data } />
            </LazyComponent>
          </ProdPageContext.Provider>
        </DisclaimerContext.Provider>
      </>
  )
}

export const PRODUCT_LISTING_QUERY = gql`
  ${PRODUCT_LISTING_BANNER_FRAGMENT}
  ${TABS_FRAGMENT}
  ${CAROUSEL_FRAGMENT}
  ${FAQ_FRAGMENT}
  ${LINKS_CTA_FRAGMENT}
  ${TWO_COL_CTA_FRAGMENT}
  ${DISCLAIMER_FRAGMENT}
  ${INFO_LINKS_FRAGMENT}
  ${PRODUCT_COMPARISON_FRAGMENT}
  ${QUICK_LINKS_FRAGMENT}
  ${CALL_OUT_FRAGMENT}
  ${COMPARISON_CTA_CARD_FRAGMENT}
  ${COMPARISON_CTA_TITLE_FRAGMENT}
  

  fragment ProductListingFragment on products_pages_product_listing_Entry {
    id
    productComparisonTitle
    secondaryPageBanner {
      ...ProductBannerFragment
    }
    offersCarousel {
      ...CarouselFragment
    }
    tabsModule {
      ...TabsModuleFragment
    }
    productComparisonTableHomeLoan {
      ...ProductComparisonFragment
    }
    comparisonCtaPicker {
      ... on comparisonCta_default_Entry {
        comparisonCta {
          ...ProductComparisonCTACardFragment
          ...ProductComparisonCTATitleFragment
        }
      }
    }
    twoColumnCta {
      ...TwoColCTAFragment
    }
    productCustomFaqs {
      ...FAQFragment
    }
    linksCta {
      ...LinksCTAFragment
    }
    disclaimer_entry_field {
      ...DisclaimerFragment
    }
    generalDisclaimers {
      ...DisclaimerFragment
    }
    informationLinks {
      ...InfoLinksFragment
    }
    quickLinksProducts {
      ...ProductQuickLinksFragment
    }
    columnCallOutTitle
    columnCallOutCards {
      ...ProductCallOutFragment
    }
  }
`