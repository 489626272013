import React, { PropsWithChildren } from 'react'
import { ProductCardDetail, ProductCardDetailProps } from './ProductCardDetail'
import { ProductCardFeature, ProductCardFeatureProps } from './ProductCardFeature'
import { ProductCardFooter, ProductCardFooterProps } from './ProductCardFooter'
import { ProductCardHeader, ProductCardHeaderProps } from './ProductCardHeader'
import { ProductCardWrapper, ProductCardWrapperProps } from './ProductCardWrapper'

export type ProductCardProps =  (
  | ({ wrapper: true; header?: false; feature?: false; detail?: false; footer?: false } & ProductCardWrapperProps)
  | ({ wrapper?: false; header: true; feature?: false; detail?: false; footer?: false } & ProductCardHeaderProps)
  | ({ wrapper?: false; header?: false; feature: true; detail?: false; footer?: false } & ProductCardFeatureProps)
  | ({ wrapper?: false; header?: false; feature?: false; detail: true; footer?: false } & ProductCardDetailProps)
  | ({ wrapper?: false; header?: false; feature?: false; detail?: false; footer: true } & ProductCardFooterProps)
  
)

export const ProductCard: React.FC<PropsWithChildren<ProductCardProps>> = ({ wrapper, header, feature, detail, footer, ...props }) => {
  if (header) {
    return (
      <ProductCardHeader { ...props as ProductCardHeaderProps}/>
    )
  }

  if (feature) {
    return (
      <ProductCardFeature { ...props as ProductCardFeatureProps}/>
    )
  }

  if (detail) {
    return (
      <ProductCardDetail { ...props as ProductCardDetailProps}/>
    )
  }

  if (footer) {
    return (
      <ProductCardFooter { ...props as ProductCardFooterProps} />
    )
  }

  return (
    <ProductCardWrapper { ...props as ProductCardWrapperProps } />
  )
}
