import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { PropsWithChildren, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import Equalizer from 'react-equalizer'
import Flickity from 'react-flickity-component'
import styled from 'styled-components'
import { useFlickityVisibilityFix } from '../../hooks/UseFlickityVisibilityFix'
import { FlickityRefObject } from '../../types/FlickityRefObject'
import { StyledFlickityComponent } from './styledProductCardCarousel'

export type ProductCardCarouselProps = Omit<React.ComponentProps<typeof Flickity>, 'flickityRef'> & {
  expanded?: boolean
  children: Array<React.ReactNode>
}

const StyledEqualizer = styled(Equalizer)`
  width: 100%;
`

export const ProductCardCarousel: React.FC<PropsWithChildren<ProductCardCarouselProps>> = ({ children, expanded, ...props }) => {
  const flickityRef = useRef(null) as FlickityRefObject
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'))
  const groupCells = useMemo(() => isMobile ? 1 : (isTablet ? 2 : 3), [isMobile, isTablet])
  const [equalizer, setEqualizer] = useState<{ updateChildrenHeights: () => void }>()
  const [contentEqualizer, setContentEqualizer] = useState<{ updateChildrenHeights: () => void }>()
  const childCount = useMemo(() => React.Children.count(children), [children])
  const enabled = useMemo(() => isTablet || childCount > 2, [isTablet, childCount])

  const observer = useMemo(() => typeof window === 'undefined' ? undefined : new ResizeObserver(() => {
    if (flickityRef.current?.flkty) {
      window.requestAnimationFrame(() => {
        if (equalizer) {
          equalizer.updateChildrenHeights()
        }

        if (contentEqualizer) {
          contentEqualizer.updateChildrenHeights()
        }

        if (flickityRef.current?.flkty) {
          flickityRef.current.flkty.resize()
        }
      })
    }
  }), [contentEqualizer, equalizer])

  // Clean-up
  useEffect(() => () => observer && observer.disconnect(), [observer])

  useFlickityVisibilityFix(flickityRef)

  useLayoutEffect(() => {
    setTimeout(() => {
      const cells = flickityRef.current?.flkty?.getCellElements()

      if (cells && observer) {
        for (const el of cells) {
          observer.observe(el)
        }
      }
    }, 0)
  }, [flickityRef, observer])

  const getNodes = useCallback((component: React.ComponentType, node: Element) => {
    const wrappers = node.querySelectorAll('[data-product-card]')

    setEqualizer(component as unknown as typeof equalizer)

    return wrappers.length > 0 ? wrappers : node.children
  }, [])

  const getContentNodes = useCallback((component: React.ComponentType, node: Element) => {
    const wrappers = node.querySelectorAll('[data-product-card] [data-same-height]')

    setContentEqualizer(component as unknown as typeof equalizer)

    return wrappers
  }, [])

  return (
    <StyledEqualizer nodes={getNodes}>
      <StyledEqualizer nodes={getContentNodes} byRow={false}>
        <StyledFlickityComponent ref={flickityRef} {...props}
          $hasOverlay={React.Children.toArray(children).length > groupCells}
          options={{
            adaptiveHeight: true,
            prevNextButtons: enabled,
            pageDots: enabled,
            draggable: enabled,
            cellAlign: 'left',
          }}>
          {children}
        </StyledFlickityComponent>
      </StyledEqualizer>
    </StyledEqualizer>
  )
}
