import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { Grid } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Image from 'next/legacy/image'
import React, { ComponentProps, PropsWithChildren } from 'react'
import { theme } from '../../theme'
import { Icon } from '../icon/icon'
import {
    ImageContainer,
    LinksTitle,
    ListRow,
    ListWrapper,
    StyledImage,
    StyledItem,
    StyledLink,
    StyledList,
    StyledTriangle,
    StyledWrapper,
} from './styledInfoLinks'

export type LinksWrapperProps = ComponentProps<typeof StyledWrapper> &  {
  image?: string
  title?: string
  }

export type LinksTitleProps =  {
    //
  }
  
export type LinksImageProps = ComponentProps<typeof StyledImage> &  {
    //
  }

export type LinksListProps = ComponentProps<typeof StyledList> &  {
    //
  }

export type LinksItemProps = ComponentProps<typeof StyledItem> &  {
  href?: string
  }

export type InfoLinksProps = 
    | (
      {
        list?: never
        item?: never
      } & LinksWrapperProps
    )
    | (
      {
        list?: never
        item?: never
      } & LinksTitleProps
    )
    | (
      {
        list?: never
        item?: never
      } & LinksImageProps
    )
    | (
      {
        list: true
        item?: never
      } & LinksListProps
    )
    | (
      {
        list?: never
        item: true
      } & LinksItemProps
    )


export const InfoLinks: React.FC<PropsWithChildren<InfoLinksProps>> = ({ list, item, children, ...props }) => {
  if (list) {
    return (
      <LinksList {...props}>{children}</LinksList>
    )
  }

  if (item) {
    return (
      <LinksItem {...props}>{children}</LinksItem>
    )
  }

  return (
    <LinksWrapper {...props}>{children}</LinksWrapper>
  )
}

const LinksWrapper: React.FC<PropsWithChildren<LinksWrapperProps>> = ({ image, title, children, ...props }) => {
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  
  return (
    <StyledWrapper 
      container
      direction={mobile ? 'column' : 'row'}
      justifyContent="center"
      alignItems={mobile ? 'center' : 'flex-start'}
      {...props}
    >
      <Grid item md={3} xs={12}>
        <Grid
          container
          direction="column"
          alignItems={mobile ? 'center' : 'flex-start'}
        >
          <LinksTitle>{title}</LinksTitle>
          <ImageContainer>
            {image && (
              <StyledImage>
                <Image src={image} alt="" layout="fill" />
              </StyledImage>
            )}
            <StyledTriangle>
              <Image src="/images/icons/triangle.svg" alt="" layout="fill" />
            </StyledTriangle>
          </ImageContainer>
        </Grid>
      </Grid>
      <Grid item md={9} xs={12}>
        <ListRow
          container
          direction="row"
        >
          {children}
        </ListRow>
      </Grid>

    </StyledWrapper>
  )
}

const LinksList: React.FC<PropsWithChildren<LinksListProps>> = ({ children, ...props }) => {
  return (
    <ListWrapper
      item
      md={6}
      xs={12}
      {...props}
    >
      <StyledList>
        {children}
      </StyledList>
    </ListWrapper>
  )
}

const LinksItem: React.FC<PropsWithChildren<LinksItemProps>> = ({ href, children, ...props }) => {
  return (
    <StyledItem {...props}><span><Icon inline icon={faChevronRight} /></span><StyledLink href={href}>{children}</StyledLink></StyledItem>
  )
}