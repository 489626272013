import { gql } from '@apollo/client'
import React, { PropsWithChildren, useContext } from 'react'
import { Accordion as AccordionBase } from '../../../components/accordion/accordion'
import { html } from '../../../utils/htmlParser'
import { isNotNull } from '../../../utils/nonNull'
import { StyledFAQHeader, StyledFAQLink, StyledFAQTitle, StyledProductFAQsContainer } from '../styledProductListing'
import { FaqFragment } from './__generated__/faqs'
import { ProdPageContext } from '../index'

export type AccordionFAQProps = {
  data: FaqFragment[]
}

export const ProductFAQs: React.FC<PropsWithChildren<AccordionFAQProps>> = ({ data }) => {
  if (!data[0] || data[0].__typename !== 'productCustomFaqs_dualColumnFaq_BlockType') {
    return null
  }

  const { prodPageId } = useContext(ProdPageContext)

  const category = data[0] && data[0].__typename === 'productCustomFaqs_dualColumnFaq_BlockType' && data[0].linkFaqCategory? data[0].linkFaqCategory[0]: null

  let categoryName = ''
  let categoryLink = '/help-and-support/faqs'

  if(isNotNull(category) && category.__typename === 'categoryFaqs_Category' ){
    categoryName = category.title? category.title: ''
    categoryLink = `/help-and-support/faqs?category=${category.id}`
  }

  const columns = [data[0].leftColumnFaqs, data[0].rightColumnFaqs]

  return (
    <>
      <StyledFAQHeader
        direction="row"
        container
        justifyContent="space-between"
        wrap="nowrap"
        id={`faq_block_${prodPageId}`}
      >
        <StyledFAQTitle variant="h5">Frequently asked questions</StyledFAQTitle>
        <StyledFAQLink href={`${categoryLink}`}>View all {categoryName} FAQs</StyledFAQLink>
    
      </StyledFAQHeader>
      <StyledProductFAQsContainer>
        <AccordionBase>
          <AccordionBase column>
            {isNotNull(columns[0]) && columns[0].map((item, i) => {
              return isNotNull(item) && item.__typename == 'faqs_default_Entry' ? (
                <AccordionBase panel key={'left-' + i}>
                  <AccordionBase title>{item.title}</AccordionBase>
                  <AccordionBase content>{html(item.faqText ?? '')}</AccordionBase>
                </AccordionBase>
              ) : null })}
          </AccordionBase>
          <AccordionBase column>
            {isNotNull(columns[1]) && columns[1].map((item, i) => {
              return isNotNull(item) &&  item.__typename == 'faqs_default_Entry' ? (
                <AccordionBase panel key={'right-' + i}>
                  <AccordionBase title>{item.title}</AccordionBase>
                  <AccordionBase content>{html(item.faqText ?? '')}</AccordionBase>
                </AccordionBase>
              ): null })}
          </AccordionBase>
        </AccordionBase>
      </StyledProductFAQsContainer>
    </>
  )
}

export const FAQ_FRAGMENT = gql`
  fragment FAQFragment on productCustomFaqs_dualColumnFaq_BlockType {
    linkFaqCategory {
      id
      title
    }
    rightColumnFaqs {
      ... on faqs_default_Entry {
        id
        title
        faqText
      }
    }
    leftColumnFaqs {
      ... on faqs_default_Entry {
        id
        title
        faqText
      }
    }
  }
`
