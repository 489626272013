import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import { Button } from '../button/button'

export const StyledCardContainer = styled(Grid)`
  border-bottom: ${props => props.theme.typography.pxToRem(1)} solid rgba(0, 0, 0, 0.25);
    
  ${props => props.theme.breakpoints.up('sm')} {
    flex: 1 1 calc(25% - 20px);
    min-height: 220px;
    flex-direction: column;
    border: ${props => props.theme.typography.pxToRem(1)} solid rgba(0, 0, 0, 0.25);
    padding: ${props => props.theme.typography.pxToRem(25)} ${props => props.theme.typography.pxToRem(25)} ${props => props.theme.typography.pxToRem(25)} ${props => props.theme.typography.pxToRem(25)};
    border-radius: ${props => props.theme.typography.pxToRem(5)};
    position: relative;
    background: ${props => props.theme.palette.common.white};
    max-width: 25%;
  }
`

export const StyledTitleText = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(20)};
  font-weight: 500;
  color: ${props => props.theme.palette.text.primary};
`

export const StyledQuickLink = styled(Grid)`
  min-width: ${props => props.theme.typography.pxToRem(180)};

  ${props => props.theme.breakpoints.up('md')} {
    min-width: unset;
  }
`

export const StyledCardWrapper = styled(Grid)`
//
`


export const StyledTitleGridWrapper = styled(Grid)`
  padding-left: ${props => props.theme.typography.pxToRem(15)};
  
  ${props => props.theme.breakpoints.up('sm')} {
    padding-left: ${props => props.theme.typography.pxToRem(30)};
  }
`

export const StyledCtaButtonWrapper = styled(Grid)`
  position: absolute;
  bottom: ${props => props.theme.typography.pxToRem(20)};
`

export const StyledCardIconContainer = styled(Grid)`
  margin-bottom: ${props => props.theme.typography.pxToRem(5)};
  color: ${props => props.theme.palette.text.primary};
`
export const StyledCardCtaButton = styled(Button)`
  color: ${props => props.theme.palette.text.primary};
  padding-left: 0px;
  margin-top: ${props => props.theme.typography.pxToRem(50)};
`