import styled from 'styled-components'
import { rem } from '../../utils/rem'
import { Container, Grid, Typography } from '@material-ui/core'
import { Spacer } from '../../components/spacer/spacer'
import {
  ProductFooterLinkButton,
  ProductFooterOutlinedButton,
} from '../../components/ProductCard/styledProductFooter'
import { StyledAltBgSection } from '../home/fragments/styledAltBgSection'
import { StyleCta, StyledContentSection } from '../../components/OfferPromotionCarousel/styledCarouselElements'
import { LinksTitle } from '../../components/furtherInfoLinks/styledInfoLinks'

// Grey background section
export const StyledGreyBackground = styled(StyledAltBgSection)`
  z-index: 1;
  padding-top: ${props => rem(2, props.theme)};
`

// Quick link wrapper
export const StyledQuickLinksWrapper = styled(Grid)`
  padding: 0 ${props => rem(40, props.theme)};
  ${props => props.theme.breakpoints.down('xs')} {
    padding: 0 ${props => rem(14, props.theme)};
  }

  ${props => props.theme.breakpoints.up('sm')} {
    margin: auto;
    padding: 0 ${props => rem(20, props.theme)} 0 ${props => rem(10, props.theme)};

    & > div > div > div > div > a {
      font-size: ${props => rem(20, props.theme)};
      max-width: ${props => rem(135, props.theme)};
    }
  }
  ${props => props.theme.breakpoints.up('md')} {
    padding: 0 ${props => rem(20, props.theme)};
  }
`

// Spacer line
export const StyledSpacer = styled(Spacer)`
  margin-top: ${props => rem(30, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${props => rem(132, props.theme)};
  }

  & > hr {
    background: #f2f2f2;
  }
`

// Offer promotion wrapper
export const StyledOfferPromotionWrapper = styled(Grid)<{$isFirstComponent: boolean}>`
  margin-top: ${props => props.$isFirstComponent ? rem(92, props.theme) : rem(0, props.theme)};
  
  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${props => props.$isFirstComponent ? rem(125, props.theme) : rem(0, props.theme)};;
  }

  ${StyledContentSection} {
    p,
    h2 {
      text-align: center;

      ${props => props.theme.breakpoints.up('md')} {
        text-align: left;
      }
    }
  }

  ${StyleCta} {
    margin-top: 0;

    ${props=> props.theme.breakpoints.up('md')} {
      margin-top: ${props => props.theme.typography.pxToRem(12)};
    }
  }
`

export const StyledTabModuleWrapper = styled(Grid)`
  margin-top: ${props => rem(72, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${props => rem(133, props.theme)};
  }

  .MuiContainer-root {
    padding-left: 0;
    padding-right: 0;
  }
`

// Product comparison wrapper
export const StyledProductComparisonHeader = styled(Typography)`
  font-weight: 500;
  text-align: left;
  font-size: ${props => props.theme.typography.pxToRem(15)};
  margin-bottom: ${props => rem(36, props.theme)};
  padding-left: ${props => props.theme.typography.pxToRem(41)};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(17)};
    text-align: center;
    margin-bottom: ${props => rem(84, props.theme)};
    padding-left: 0;
  }
`

export const StyledProductComparisonWrapper = styled(Grid)`
  margin-top: ${props => rem(84, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${props => rem(125, props.theme)};
  }
`

export const StyledButton = styled(ProductFooterOutlinedButton)<{target?: string}>`
  padding: ${props => props.theme.typography.pxToRem(15)} ${props => props.theme.typography.pxToRem(25)};
`

export const StyledLinkButton = styled(ProductFooterLinkButton)`
  color: black;
`

// Two columns CTA wrapper
export const StyledTwoColCTAWrapper = styled(Grid)`
  position: relative;
  margin-top: ${props => rem(88, props.theme)};
  padding-bottom: ${props => props.theme.typography.pxToRem(20)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin: auto;
    margin-top: ${props => rem(162, props.theme)};
  }
`
// Comparison CTA Wrapper
export const StyledComparisonCTAWrapper = styled(Grid)`
  position: relative;
  margin-top: ${props => rem(71, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin: auto;
    margin-top: ${props => rem(110, props.theme)};
  }
`

// FAQ sections
export const StyledFAQTitle = styled(Typography)`
  font-weight: 500;
  font-size: ${props => props.theme.typography.pxToRem(15)};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(17)};
    margin-left: 0;
  }
`

export const StyledFAQLink = styled.a`
  display: none;

  ${props => props.theme.breakpoints.up('sm')} {
    display: inline-block;
    color: black;
    font-weight: 500;
    font-size: ${props => props.theme.typography.pxToRem(15)};
  }
`

export const StyledFAQHeader = styled(Grid)`
  margin-bottom: ${props => rem(35, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-bottom: ${props => rem(76, props.theme)};
  }
`

export const StyledProductFAQsContainer = styled.div`
  & > div > div {
    padding-right: 0;

    & > div > div {
      border-radius: ${props => props.theme.typography.pxToRem(4)};
      margin-bottom: ${props => props.theme.typography.pxToRem(14)};
      background-color: #f2f2f2;

      &::before {
        height: 0;
      }

      & > div {
        align-items: center;
      }

      & > div > div > div {
        color: #4d4d4d;
        font-size: ${props => props.theme.typography.pxToRem(16)};
        font-weight: 500;
        margin: ${props => props.theme.typography.pxToRem(10)} 0;

        ${props => props.theme.breakpoints.up('sm')} {
          font-size: ${props => props.theme.typography.pxToRem(22)};
        }
      }
    }
  }

  ${props => props.theme.breakpoints.up('sm')} {
    .MuiCollapse-wrapperInner {
      margin-top: 0;
    }

    & > div > div:nth-child(1) {
      padding-right: ${props => props.theme.typography.pxToRem(20)};
    }
  }
`

export const StyledFAQsWrapper= styled(Container)`
  margin-top: ${props => rem(83, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${props => rem(162, props.theme)};
  }
`

// Link section
export const StyledInfoLinkWrapper = styled(Grid)`
  ${props => props.theme.breakpoints.up('sm')} {
    > .MuiGrid-root {
      justify-content: flex-start;
      min-height: unset;
    }

    padding-top: ${props => rem(86.5, props.theme)};
  }

  ${LinksTitle} {
    margin-top: ${props => props.theme.typography.pxToRem(80)};
  }
`

export const StyledLinkCTAWrapper = styled(Grid)`
  margin-top: ${props => rem(78, props.theme)};
  ${props => props.theme.breakpoints.up('sm')} {
    margin: auto;
    margin-top: ${props => rem(186, props.theme)};
  }
`

export const SlantedTopGreyBackground = styled.div<{$isComparisonCtaComponent: boolean}>`
  position: relative;
  padding-top: ${props => rem(48, props.theme)};
  padding-bottom: ${props => props.$isComparisonCtaComponent ? props.theme.typography.pxToRem(25) : '0px'};
  &::before {
    content: "";
    top: ${props => rem(-1, props.theme)};
    right: 0;
    bottom: 0;
    left: 0;
    background: #f8f8f8;
    position: absolute;
    z-index: -1;
    clip-path: ${props => props.$isComparisonCtaComponent ? 'unset' : 'polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 150px))'};
  }
`

export const SlantedBottomGreyBackground = styled.div`
  position: relative;
  margin-top: ${props => rem(65, props.theme)};
  margin-bottom: ${props => rem(150, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${props => rem(143, props.theme)};
  }

  &::before {
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #f2f2f2;
    position: absolute;
    z-index: -1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 150px));
  }
`

// Disclaimer wrapper
export const StyledDisclaimerWrapper = styled.div`
  margin-bottom: ${props => rem(77, props.theme)};
  margin-top: ${props => rem(72, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${props => rem(158, props.theme)};
  }
`

// Whole product listing page wrapper
export const StyledProductWrapper = styled.div`
  margin-top: ${props => rem(100, props.theme)};
`
