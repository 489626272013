import { Grid, Paper } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { irem } from '../../utils'

export type ProductCardWrapperProps = React.ComponentProps<typeof Paper> & {
  //
}

export const ProductCardPaper = styled(Paper)`
  min-width: ${irem(300)};
  max-width: calc(100vw - ${irem(55)});
  width: ${props => props.theme.typography.pxToRem(340)};
  padding-top: ${props => props.theme.typography.pxToRem(37)};
  padding-bottom: ${props => props.theme.typography.pxToRem(37)};
  margin-right: ${props => props.theme.typography.pxToRem(10)};

  ${props => props.theme.breakpoints.up('sm')} {
    width: ${props => props.theme.typography.pxToRem(436)};
    padding-top: ${props => props.theme.typography.pxToRem(52)};
    padding-bottom: ${props => props.theme.typography.pxToRem(52)};
    margin-right: ${props => props.theme.typography.pxToRem(40)};
  }
`

export const ProductCardWrapper: React.FC<PropsWithChildren<ProductCardWrapperProps>> = ({ children, ...props }) => {
  return (
    <ProductCardPaper variant="outlined" {...props} data-product-card>
      <Grid container direction={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} style={{ height: '100%' }}>
        { children }
      </Grid>
    </ProductCardPaper>
  )
}
