import { gql } from '@apollo/client'
import { Typography } from '@material-ui/core'
import React, { PropsWithChildren, useContext } from 'react'
import styled from 'styled-components'
import { Disclaimer as DisclaimerBase } from '../../../components/disclaimer/disclaimer'
import { DisclaimerContext } from '../../../context/disclaimerContext'
import { html } from '../../../utils/htmlParser'
import { nonNull } from '../../../utils/nonNull'
import { ProdPageContext } from '../index'
import { DisclaimerFragment } from './__generated__/disclaimer'

export const StyledProdDisclaimerSymbol = styled(Typography)`
  vertical-align: middle;
  font-size: ${props => props.theme.typography.pxToRem(10)};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  padding-right: ${props => props.theme.typography.pxToRem(3)};
`

export const StyledProdDisclaimerBase = styled(DisclaimerBase)`
  & > p {
    display: inline;
  }
`
export const StyledProdDisclaimerWrapper = styled(DisclaimerBase)`
  background: #f2f2f2;
  gap: 0;

  > div:first-child {
    background: #fff;
    padding-bottom: ${props => props.theme.typography.pxToRem(35)};
  }

  > div:nth-child(2) {
    padding-top: ${props => props.theme.typography.pxToRem(25)};
    padding-bottom: 0;
  }

  > div:nth-child(n+3) {
    padding-bottom: 0;
  }

  > div:last-child {
    padding-bottom: ${props => props.theme.typography.pxToRem(25)};
  }
`
export const StyledProdDisclaimerTitle= styled(DisclaimerBase)`
  & > h5 {
    margin-left: ${props => props.theme.typography.pxToRem(20)};
  }
`

export type DisclaimerProps = {
  data?: DisclaimerFragment[]
  general?: DisclaimerFragment[]
}

export const Disclaimer: React.FC<PropsWithChildren<DisclaimerProps>> = ({ data, general }) => {
  const { prodPageId } = useContext(ProdPageContext)

  const { disclaimerArray, iconMap } = useContext(DisclaimerContext)

  return (
    <>
      {((disclaimerArray && disclaimerArray.length > 0) || (general && general.length > 0) || (data && data.length > 0)) &&
      <StyledProdDisclaimerWrapper>
        <StyledProdDisclaimerTitle id={`disclaimer_block_${prodPageId}`} title>{'INFORMATION YOU SHOULD KNOW'}</StyledProdDisclaimerTitle>
        {general && nonNull(general).map((item, index) => {
          if (item.__typename === 'customDisclaimers_default_Entry' || item.__typename === 'homeLoanDisclaimers_default_Entry' || item.__typename === 'personalloandisclaimers_default_Entry') {
            return (
              <StyledProdDisclaimerBase key={index} textBlock>
                {item.disclaimerText && html(item.disclaimerText)}
              </StyledProdDisclaimerBase>
            )
          }

          return null
        })}
        {disclaimerArray && nonNull(disclaimerArray).map((d, index) => {
          return (
            <StyledProdDisclaimerBase key={index} textBlock>
              <StyledProdDisclaimerSymbol>
                {iconMap.get(d.disclaimerId)}
              </StyledProdDisclaimerSymbol>
              {d?.disclaimerText && html(d.disclaimerText)}
            </StyledProdDisclaimerBase>
          )
        })}
        {data && nonNull(data).map((item, index) => {
          if (item.__typename === 'customDisclaimers_default_Entry' || item.__typename === 'homeLoanDisclaimers_default_Entry' || item.__typename === 'personalloandisclaimers_default_Entry') {
            return (
              <StyledProdDisclaimerBase key={index} textBlock>
                <StyledProdDisclaimerSymbol key={index}>
                  {index+1}
                </StyledProdDisclaimerSymbol>
                {item.disclaimerText && html(item.disclaimerText)}
              </StyledProdDisclaimerBase>
            )
          }
  
          return null
        })}
      </StyledProdDisclaimerWrapper>
      }
    </>
  )
}

export const DISCLAIMER_FRAGMENT = gql`
  fragment DisclaimerFragment on EntryInterface {
    id
    title
    ... on customDisclaimers_default_Entry {
      disclaimerText
    }
    ... on homeLoanDisclaimers_default_Entry {
      disclaimerText
    }
    ... on personalloandisclaimers_default_Entry {
      disclaimerText
    }
  }
`