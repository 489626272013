import styled from 'styled-components'
import { Icon } from '../icon/icon'
import { StyledTitleText } from './styledComparisonCtaCard'

export const StyledAccordion = styled.a`
  text-decoration: none;
  color: #d83b00;
`

export const StyledAccordionDetails = styled.div`
  padding-left: ${props => props.theme.typography.pxToRem(4)};
  padding-right: ${props => props.theme.typography.pxToRem(4)};
`

export const StyledChevron = styled(Icon)`
  position: absolute;
  right: ${props => props.theme.typography.pxToRem(0)};
  top: 35px;
  font-size: ${props => props.theme.typography.pxToRem(17)};
  transition: 0.2s linear;
`

export const StyledAccordionSummary = styled.div`
  padding-left: ${props => props.theme.typography.pxToRem(4)};
  padding-right: ${props => props.theme.typography.pxToRem(4)};
  padding-top: ${props => props.theme.typography.pxToRem(23)};
  padding-bottom: ${props => props.theme.typography.pxToRem(23)};
  position: relative;

  &:hover {
    ${StyledTitleText} {
      color: ${props => props.theme.palette.secondary.dark};
    }
    ${StyledChevron} {
      right: ${props => props.theme.typography.pxToRem(-10)};
    }
  }
`