import Grid from '@material-ui/core/Grid'
import React, { ComponentProps, PropsWithChildren } from 'react'
import styled from 'styled-components'

export type ComparisonCtaWrapperProps =  Omit<ComponentProps<typeof Grid>, 'direction'> & {
    //
}

export const StyledCtaWrapper = styled(Grid)`
    flex-wrap: wrap;
    ${props => props.theme.breakpoints.up('sm')} {
        gap: ${props => props.theme.typography.pxToRem(2)};
        flex-wrap: nowrap;
    }

    ${props => props.theme.breakpoints.up('md')} {
        gap: ${props => props.theme.typography.pxToRem(10)};
    }

    ${props => props.theme.breakpoints.up('lg')} {
        gap: ${props => props.theme.typography.pxToRem(20)};
    }
`

export const ComparisonCtaWrapper: React.FC<PropsWithChildren<ComparisonCtaWrapperProps>> = ({  container = true, alignContent = 'center', justifyContent = 'space-between', children, ...props }) => {
    return (
        <StyledCtaWrapper item lg={12} xl={12} container={container} justifyContent={"space-between"} { ...props }>
            { children }
        </StyledCtaWrapper>
    )
}
