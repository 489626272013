import { gql } from '@apollo/client'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { ContactCTA } from '../../../components/contactcta/contactcta'
import { GlobalLinksCtaFragment } from '../../../fragments/__generated__/linksCtaFallback'
import { isNotNull } from '../../../utils/nonNull'
import { LinksCtaFragment } from './__generated__/linksCta'

export type LinksCTAProps = {
  data: LinksCtaFragment[]
  fallback: GlobalLinksCtaFragment
}

const StyledContactCTA = styled(ContactCTA)`
  margin-bottom: ${props => props.theme.typography.pxToRem(-70)};
`

export const LinksCTA: React.FC<PropsWithChildren<LinksCTAProps>> = ({ data, fallback }) => {
  const fallbackCta = fallback ? fallback : null

  return ((data?.[0]?.linksCtaTitle && data[0] && data[0].__typename === 'linksCta_BlockType') ? (
    <StyledContactCTA>
      <ContactCTA headingWrapper>
        {data?.[0]?.linksCtaTitle && <ContactCTA title>{data?.[0].linksCtaTitle}</ContactCTA>}
        {data?.[0]?.linksCtaSubtitle && <ContactCTA subtitle>{data?.[0]?.linksCtaSubtitle}</ContactCTA>}
      </ContactCTA>
      <ContactCTA contentWrapper>
        {data?.[0]?.linksCTALink1 && data?.[0]?.linksCTALink1.__typename === 'linkField_Link' && isNotNull(data?.[0]?.linksCTALink1.url) && isNotNull(data?.[0]?.linksCTALink1.customText) && (
          <ContactCTA section type="wrapper" href={data?.[0]?.linksCTALink1.url || '#'} target={data?.[0]?.linksCTALink1.target === '_blank' ? '_blank' : '_self'}>
            {isNotNull(data?.[0]?.linksCta1Icon) ? <ContactCTA icon iconCode={data?.[0]?.linksCta1Icon}/> : '#'}
            <ContactCTA section type="content">
              <ContactCTA title>{data?.[0]?.linksCTALink1.customText}</ContactCTA>
              {data?.[0]?.linksCta1Subtext && <ContactCTA subtitle>{data?.[0]?.linksCta1Subtext}</ContactCTA>}
            </ContactCTA>
          </ContactCTA>
        )}
        {data?.[0]?.linksCTALink2 && data?.[0]?.linksCTALink2.__typename === 'linkField_Link' && isNotNull(data?.[0]?.linksCTALink2.url) && isNotNull(data?.[0]?.linksCTALink2.customText) && (
          <ContactCTA section type="wrapper" href={data?.[0]?.linksCTALink2.url || '#'} target={data?.[0]?.linksCTALink2.target === '_blank' ? '_blank' : '_self'}>
            {isNotNull(data?.[0]?.linksCta2Icon) ? <ContactCTA icon iconCode={data?.[0]?.linksCta2Icon}/> : '#'}
            <ContactCTA section type="content">
              <ContactCTA title>{data?.[0]?.linksCTALink2.customText}</ContactCTA>
              {data?.[0]?.linksCta2Subtext && <ContactCTA subtitle>{data?.[0]?.linksCta2Subtext}</ContactCTA>}
            </ContactCTA>
          </ContactCTA>
        )}
        {data?.[0]?.linksCTALink3 && data?.[0]?.linksCTALink3.__typename === 'linkField_Link' && isNotNull(data?.[0]?.linksCTALink3.url) && isNotNull(data?.[0]?.linksCTALink3.customText) && (
          <ContactCTA section type="wrapper" href={data?.[0]?.linksCTALink3.url || '#'} target={data?.[0]?.linksCTALink3.target === '_blank' ? '_blank' : '_self'}>
            {isNotNull(data?.[0]?.linksCta3Icon) ? <ContactCTA icon iconCode={data?.[0]?.linksCta3Icon}/> : '#'}
            <ContactCTA section type="content">
              <ContactCTA title>{data?.[0]?.linksCTALink3.customText}</ContactCTA>
              {data?.[0]?.linksCta3Subtext && <ContactCTA subtitle>{data?.[0]?.linksCta3Subtext}</ContactCTA>}
            </ContactCTA>
          </ContactCTA>
        )}
        { data?.[0]?.linksCTALink4 && data?.[0]?.linksCTALink4.__typename === 'linkField_Link' && isNotNull(data?.[0]?.linksCTALink4.url) && isNotNull(data?.[0]?.linksCTALink4.customText) && (
          <ContactCTA section type="wrapper" href={data?.[0]?.linksCTALink4.url || '#'} target={ data?.[0]?.linksCTALink4.target === '_blank' ? '_blank' : '_self'}>
            {isNotNull(data?.[0]?.linksCta4Icon) ? <ContactCTA icon iconCode={data?.[0]?.linksCta4Icon}/> : '#'}
            <ContactCTA section type="content">
              <ContactCTA title>{data?.[0]?.linksCTALink4.customText}</ContactCTA>
              {data?.[0]?.linksCta4Subtext && <ContactCTA subtitle>{data?.[0]?.linksCta4Subtext}</ContactCTA>}
            </ContactCTA>
          </ContactCTA>
        )}
      </ContactCTA>
    </StyledContactCTA>
  ) : (fallbackCta ? (
    <StyledContactCTA>
      <ContactCTA headingWrapper>
        {fallbackCta?.globalLinksCta?.[0]?.linksCtaTitle && <ContactCTA title>{fallbackCta.globalLinksCta[0].linksCtaTitle}</ContactCTA>}
        {fallbackCta?.globalLinksCta?.[0]?.linksCtaSubtitle && <ContactCTA subtitle>{fallbackCta?.globalLinksCta?.[0]?.linksCtaSubtitle}</ContactCTA>}
      </ContactCTA>
      <ContactCTA contentWrapper>
        { fallbackCta?.globalLinksCta?.[0]?.linksCTALink1 && fallbackCta?.globalLinksCta?.[0]?.linksCTALink1.__typename === 'linkField_Link' && isNotNull(fallbackCta?.globalLinksCta?.[0]?.linksCTALink1.url) && isNotNull(fallbackCta?.globalLinksCta?.[0]?.linksCTALink1.customText) && (
          <ContactCTA section type="wrapper" href={fallbackCta?.globalLinksCta?.[0]?.linksCTALink1.url || '#'} target={ fallbackCta?.globalLinksCta?.[0]?.linksCTALink1.target === '_blank' ? '_blank' : '_self'}>
            {isNotNull(fallbackCta?.globalLinksCta?.[0]?.linksCta1Icon) ? <ContactCTA icon iconCode={fallbackCta?.globalLinksCta?.[0]?.linksCta1Icon}/> : '#'}
            <ContactCTA section type="content">
              <ContactCTA title>{fallbackCta?.globalLinksCta?.[0]?.linksCTALink1.customText}</ContactCTA>
              {fallbackCta?.globalLinksCta?.[0]?.linksCta1Subtext && <ContactCTA subtitle>{fallbackCta?.globalLinksCta?.[0]?.linksCta1Subtext}</ContactCTA>}
            </ContactCTA>
          </ContactCTA>
        )}
        { fallbackCta?.globalLinksCta?.[0]?.linksCTALink2 && fallbackCta?.globalLinksCta?.[0]?.linksCTALink2.__typename === 'linkField_Link' && isNotNull(fallbackCta?.globalLinksCta?.[0]?.linksCTALink2.url) && isNotNull(fallbackCta?.globalLinksCta?.[0]?.linksCTALink2.customText) && (
          <ContactCTA section type="wrapper" href={fallbackCta?.globalLinksCta?.[0]?.linksCTALink2.url || '#'} target={ fallbackCta?.globalLinksCta?.[0]?.linksCTALink2.target === '_blank' ? '_blank' : '_self'}>
            {isNotNull(fallbackCta?.globalLinksCta?.[0]?.linksCta2Icon) ? <ContactCTA icon iconCode={fallbackCta?.globalLinksCta?.[0]?.linksCta2Icon}/> : '#'}
            <ContactCTA section type="content">
              <ContactCTA title>{fallbackCta?.globalLinksCta?.[0]?.linksCTALink2.customText}</ContactCTA>
              {fallbackCta?.globalLinksCta?.[0]?.linksCta2Subtext && <ContactCTA subtitle>{fallbackCta?.globalLinksCta?.[0]?.linksCta2Subtext}</ContactCTA>}
            </ContactCTA>
          </ContactCTA>
        )}
        { fallbackCta?.globalLinksCta?.[0]?.linksCTALink3 && fallbackCta?.globalLinksCta?.[0]?.linksCTALink3.__typename === 'linkField_Link' && isNotNull(fallbackCta?.globalLinksCta?.[0]?.linksCTALink3.url) && isNotNull(fallbackCta?.globalLinksCta?.[0]?.linksCTALink3.customText) && (
          <ContactCTA section type="wrapper" href={fallbackCta?.globalLinksCta?.[0]?.linksCTALink3.url || '#'} target={ fallbackCta?.globalLinksCta?.[0]?.linksCTALink3.target === '_blank' ? '_blank' : '_self'}>
            {isNotNull(fallbackCta?.globalLinksCta?.[0]?.linksCta3Icon) ? <ContactCTA icon iconCode={fallbackCta?.globalLinksCta?.[0]?.linksCta3Icon}/> : '#'}
            <ContactCTA section type="content">
              <ContactCTA title>{fallbackCta?.globalLinksCta?.[0]?.linksCTALink3.customText}</ContactCTA>
              {fallbackCta?.globalLinksCta?.[0]?.linksCta3Subtext && <ContactCTA subtitle>{fallbackCta?.globalLinksCta?.[0]?.linksCta3Subtext}</ContactCTA>}
            </ContactCTA>
          </ContactCTA>
        )}
        { fallbackCta?.globalLinksCta?.[0]?.linksCTALink4 && fallbackCta?.globalLinksCta?.[0]?.linksCTALink4.__typename === 'linkField_Link' && isNotNull(fallbackCta?.globalLinksCta?.[0]?.linksCTALink4.url) && isNotNull(fallbackCta?.globalLinksCta?.[0]?.linksCTALink4.customText) && (
          <ContactCTA section type="wrapper" href={fallbackCta?.globalLinksCta?.[0]?.linksCTALink4.url || '#'} target={ fallbackCta?.globalLinksCta?.[0]?.linksCTALink4.target === '_blank' ? '_blank' : '_self'}>
            {isNotNull(fallbackCta?.globalLinksCta?.[0]?.linksCta4Icon) ? <ContactCTA icon iconCode={fallbackCta?.globalLinksCta?.[0]?.linksCta4Icon}/> : '#'}
            <ContactCTA section type="content">
              <ContactCTA title>{fallbackCta?.globalLinksCta?.[0]?.linksCTALink4.customText}</ContactCTA>
              {fallbackCta?.globalLinksCta?.[0]?.linksCta4Subtext && <ContactCTA subtitle>{fallbackCta?.globalLinksCta?.[0]?.linksCta4Subtext}</ContactCTA>}
            </ContactCTA>
          </ContactCTA>
        )}
      </ContactCTA>
    </StyledContactCTA>
  ) : null)
  )
}

export const LINKS_CTA_FRAGMENT = gql`
  fragment LinksCTAFragment on linksCta_BlockType {
    linksCtaTitle
    linksCtaSubtitle
    linksCta1Icon
    linksCTALink1 {
      url
      customText
      target
    }
    linksCta1Subtext
    linksCta2Icon
    linksCTALink2 {
      url
      customText
      target
    }
    linksCta2Subtext
    linksCta3Icon
    linksCTALink3 {
      url
      customText
      target
    }
    linksCta3Subtext
    linksCta4Icon
    linksCTALink4 {
      url
      customText
      target
    }
    linksCta4Subtext
  }
`
