import styled from 'styled-components'
import Flickity from 'react-flickity-component'
import { irem } from '../../utils'

export const StyledFlickityComponent = styled(Flickity)<{ $hasOverlay?: boolean }>`
  width: 100%;
  position: relative;
  outline: none;

  .flickity-button {
    display: none;

    &:disabled {
      opacity: 0.3;
      cursor: auto;
      pointer-events: none;
    }
  }

  ${props => props.theme.breakpoints.up('sm')} {
    .flickity-button {
      display: block;
      position: absolute;
      background: hsla(0, 0%, 100%, 0.75);
      border: none;
      color: ${props=> props.theme.palette.text.primary};

      &:hover {
        cursor: pointer;

        > .flickity-button-icon {
          fill: ${props=> props.theme.palette.primary.main};
        }
      }
    }

    .flickity-prev-next-button {
      top: ${irem(240)};
      width: ${props => props.theme.typography.pxToRem(84)};
      height: ${props => props.theme.typography.pxToRem(84)};
      padding: ${props => props.theme.typography.pxToRem(28)};
      border-radius: 50%;
      border: rgba(0, 0, 0) solid ${props => props.theme.typography.pxToRem(1)};
      background: ${props => props.theme.palette.background.default};

      &:hover {
        cursor: pointer;
      }
    }

    .previous {
      transform: translateX(-200%);
      left: 0;
    }

    .next {
      transform: translateX(200%);
      right: 0;
    }
  }

  .flickity-viewport {
    overflow: visible;

    &:focus {
      outline: 0 !important;
    }

    ${props => props.theme.breakpoints.up('sm')} {
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: ${props => props.theme.typography.pxToRem(140)};
      }

      &::before {
        left: calc(50% - 50vw);
        background: ${props => props.$hasOverlay ? 'linear-gradient(to left, rgba(255, 255, 255, 0), #f8f8f8)' : 'rgba(255, 255, 255, 0)'};
      }

      &::after {
        right: calc(50% - 50vw);
        background: ${props => props.$hasOverlay ? 'linear-gradient(to right, rgba(255, 255, 255, 0), #f8f8f8)' : 'rgba(255, 255, 255, 0)'};
      }
    }
  }

  .flickity-page-dots {
    position: absolute;
    left: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
    margin-top: ${props => props.theme.typography.pxToRem(20)};
    margin-left: ${props => props.theme.typography.pxToRem(-5)};

    ${props => props.theme.breakpoints.up('sm')} {
      margin-top: ${props => props.theme.typography.pxToRem(40)};
      margin-left: 0;
      text-align: left;
    }

    li {
      margin: 0;

      &::before {
        display: none;
      }
    }

    .dot {
      display: inline-block;
      width: ${props => props.theme.typography.pxToRem(10)};
      height: ${props => props.theme.typography.pxToRem(10)};
      margin: 0 ${props => props.theme.typography.pxToRem(8)};
      background: #333;
      border-radius: 50%;
      opacity: 0.25;
      cursor: pointer;

      &.is-selected {
        opacity: 1;
        color: ${props => props.theme.palette.primary.main};
        background: ${props => props.theme.palette.primary.main};
      }
    }
  }
`