import { gql } from '@apollo/client'
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons'
import React, { PropsWithChildren, useContext, useState } from 'react'
import styled from 'styled-components'
import { ProductCard } from '../../../components/ProductCard/ProductCard'
import { ProductCardRateType } from '../../../components/ProductCard/ProductCardHeader'
import {
  ProductCardFeatureSpan,
  ProductCardFeatureText,
  ProductCardIcon,
} from '../../../components/ProductCard/styledProductCardFeature'
import {
  ProductCardTable,
  ProductCardTableBody,
  ProductCardTableIcon,
  ProductCardTableRow,
  ProductCardTableText,
  ProductTableKeyCell,
  ProductTableValueCell,
} from '../../../components/ProductCard/styledProductCardTable'
import { ProductCardCarousel } from '../../../components/ProductCardCarousel/ProductCardCarousel'
import { Link } from '../../../components/link/link'
import { DisclaimerContext } from '../../../context/disclaimerContext'
import { DisclaimersFragment } from '../../../fragments/__generated__/ratesTable'
import { Maybe } from '../../../gql'
import { isNotNull, nonNull } from '../../../utils/nonNull'
import { StyledButton, StyledLinkButton } from '../styledProductListing'
import { ProductComparisonFragment } from './__generated__/productComparisonTable'

export type ProductComparisonProps = {
  data: ProductComparisonFragment[]
}

export const ProductComparison: React.FC<PropsWithChildren<ProductComparisonProps>> = ({ data }) => {
  if (!data[0] || data[0].__typename !== 'productComparisonTableHomeLoan_BlockType') {
    return null
  }

  const [isShowDetail, setShowDetail] = useState(false)
  const onCardExpanded = () => {
    setShowDetail(!isShowDetail)
  }

  const { pageId } = useContext(DisclaimerContext)

  return (
    <ProductCardCarousel expanded={isShowDetail}>
      {data.map((item, index) => {return item.__typename !== 'productComparisonTableHomeLoan_BlockType' ? null : (
        <ProductCard wrapper key={index}>
          {isNotNull(item.productRate) && isNotNull(item.productRate[0]) && (item.productRate[0].__typename === 'rates_default_Entry' || item.productRate[0].__typename === 'productRatesCustom_default_Entry') ? (
            <ProductCard header heading={item.productTitle? item.productTitle: ''} href={item?.learnMoreLink?.url || '#'} rates={item.productRate[0] as ProductCardRateType}>
              {item.productDescription}
              { item.productRate[0] && item.productRate[0].__typename === 'rates_default_Entry' &&
              <Link href={`#disclaimer_block_${pageId}`} passHref>
                <StyledDiscliamerLink>
                  <DisclaimerIcons disclaimers={[ ...(item.productRate[0]?.homeLoanDisclaimers ?? []), ...(item.productRate[0]?.personalLoanDisclaimers ?? []) ]} />
                </StyledDiscliamerLink>
              </Link>
              }
            </ProductCard>
          ) : (
            <ProductCard header heading={item.productTitle? item.productTitle: ''} href={item?.learnMoreLink?.url || '#'}>
              {item.productDescription}
            </ProductCard>
          )}
          <ProductCard feature>
            {isNotNull(item.productBenefits) && item.productBenefits.map((productData, index) => {return isNotNull(productData) && productData.__typename == 'productBenefits_TableRow' ?(
              <ProductCardFeatureSpan key={index}>
                <ProductCardIcon icon={faCheckCircle}/>
                <ProductCardFeatureText>
                  {productData.benefit}
                </ProductCardFeatureText>
              </ProductCardFeatureSpan>
            ): null })}
          </ProductCard>
          <ProductCard detail expanded={isShowDetail} onDetailExpand={onCardExpanded} enabled={(item.productComparisonTable?.length ?? 0) > 0}>
            <ProductCardTable>
              <ProductCardTableBody>
                {item.productComparisonTable && item.productComparisonTable.map((item, index) => {
                  if(isNotNull(item)&& item.__typename == 'productComparisonTable_comparisonField_BlockType') {
                    const { fieldTitle, fieldText, fieldIcon } = item

                    return (
                      <ProductCardTableRow key={index}>
                        <ProductTableKeyCell component="th" scope="row">
                          {fieldTitle}
                        </ProductTableKeyCell>
                        <ProductTableValueCell>
                          {fieldIcon !== 'unset' ? <ProductCardTableIcon icon={fieldIcon == 'tick' ? faCheckCircle : faTimesCircle} /> : null}
                          {fieldText && <ProductCardTableText>{fieldText}</ProductCardTableText>}
                        </ProductTableValueCell>
                      </ProductCardTableRow>
                    )
                  } else {
                    return null
                  }})}
              </ProductCardTableBody>
            </ProductCardTable>
          </ProductCard>
          <ProductCard footer>
            <StyledButton target="_blank" variant={'outlined'} href={item?.buttonLink?.url || '#'}>
              Apply now
            </StyledButton>
            <StyledLinkButton href={item?.learnMoreLink?.url || '#'}>
              Learn more
            </StyledLinkButton>
          </ProductCard>
        </ProductCard>
      )})}
    </ProductCardCarousel>
  )
}

export const DISCLAIMERS_FRAGMENT = gql`
  fragment DisclaimersFragment on EntryInterface {
    ... on homeLoanDisclaimers_default_Entry {
      disclaimerId
      disclaimerText
    }
    ... on personalloandisclaimers_default_Entry {
      disclaimerId
      disclaimerText
    }
    ... on customDisclaimers_default_Entry {
      disclaimerText
    }
  }
`

export const PRODUCT_COMPARISON_FRAGMENT = gql`
  ${DISCLAIMERS_FRAGMENT}
  fragment ProductComparisonFragment on productComparisonTableHomeLoan_BlockType {
    id
    productTitle
    learnMoreLink {
      target
      url
    }
    buttonLink {
      target
      url
    }
    productRate {
      ... on rates_default_Entry {
        productComparisonRate
        productComparisonRateTitle
        productPrimaryRate
        productPrimaryRateType
        homeLoanDisclaimers {
          ...DisclaimersFragment
        }
        personalLoanDisclaimers {
          ...DisclaimersFragment
        }
      }
    ... on productRatesCustom_default_Entry {
        productPrimaryRate
        productBonusRate
        productComparisonRate
        productComparisonRateTitle
        productPrimaryRateType
        disclaimer_entry_field {
          ...DisclaimersFragment
        }
      }
    }
    productDescription
    productBenefits {
      benefit
    }
    productComparisonTable {
      ... on productComparisonTable_comparisonField_BlockType {
        id
        fieldTitle
        fieldIcon
        fieldText
      }
    }
  }
`
export const StyledIcon = styled.span`
  & + &::before {
    content: ' ';
  }
`
export const StyledDiscliamerLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.palette.primary.main};
  vertical-align: super;
  font-size: 0.65em;
  font-weight: 500;
  padding-left: 3px;
`

export const DisclaimerIcons: React.VFC<{ disclaimers: Maybe<DisclaimersFragment>[] }> = ({ disclaimers }) => {
  const { iconMap } = useContext(DisclaimerContext)

  const icons = nonNull(disclaimers).map(d => {
    if ((d.__typename === 'homeLoanDisclaimers_default_Entry' || d.__typename === 'personalloandisclaimers_default_Entry') && d.disclaimerId && iconMap.has(d.disclaimerId)) {
      return (
        <StyledIcon key={d.disclaimerId}>
          {iconMap.get(d.disclaimerId)}
        </StyledIcon>
      )
    }

    return null
  }).filter(isNotNull)

  return (
    <>
      {icons}
    </>
  )
}
