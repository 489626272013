import { gql } from '@apollo/client'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { ComparisonCta } from '../../../components/comparisonCta/ComparisonCta'
import { ProductComparisonCtaCardFragment, ProductComparisonCtaTitleFragment } from './__generated__/comparsionCTA'

export type ComparisonCtaTitleData  ={
    titleData: string | null | undefined
}

export type ComparisonCtaCardData = {
    cardData: ProductComparisonCtaCardFragment[]
}

const StyledCompCtaTitle = styled(Typography)`
  font-weight: 500;
  text-align: center;
  font-size: ${props => props.theme.typography.pxToRem(23)};
  margin: 0;
  width: 100%;
  
  ${props => props.theme.breakpoints.up('sm')} {
    margin-bottom: ${props => props.theme.typography.pxToRem(50)};
  }
    
  ${props=> props.theme.breakpoints.up('md')} {
    display: -webkit-box;
    -webkit-line-clamp: none;
    -webkit-box-orient: vertical;
    font-size: ${props => props.theme.typography.pxToRem(32)};
    padding-top: 0;
    line-height: ${props => props.theme.typography.pxToRem(45)};
  }
`

export const ComparisonCtaComponent: React.FC<PropsWithChildren<ComparisonCtaTitleData & ComparisonCtaCardData>> = ({titleData, cardData}) => (
    <>
        <StyledCompCtaTitle variant="h2">{titleData ? titleData : "What's next"}</StyledCompCtaTitle>

        <ComparisonCta>
            {cardData?.map((card, index) => (
                <ComparisonCta key={index} card iconCode={card?.cardIcon || ''} title={card?.cardText || 'Learn more'} href={card?.cardLink?.url || ''}>
                </ComparisonCta>
            ))}
        </ComparisonCta>
    </>
)

export const COMPARISON_CTA_CARD_FRAGMENT = gql`
    fragment ProductComparisonCTACardFragment on comparisonCta_comparisonCtaCard_BlockType {
        cardIcon
        cardText
        cardLink {
            url
            target
        }
    }
`
export const COMPARISON_CTA_TITLE_FRAGMENT = gql`
    fragment ProductComparisonCTATitleFragment on comparisonCta_comparisonCtaTitleBlock_BlockType {
        comparisonCtaTitle
    }
`