import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons'
import Grid from '@material-ui/core/Grid'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledAccordionTitle,
  StyledAccordionTitleIcon,
  StyledAccordionTitleSpan,
  StyledProductCardDetailContainer,
  StyledSectionHorizontalLine,
} from './styledProductCardDetail'

export type ProductCardDetailProps = React.ComponentProps<typeof StyledProductCardDetailContainer> & {
  enabled?: boolean
  expanded?: boolean
  onDetailExpand?: () => void
}

export const ProductCardDetail: React.FC<PropsWithChildren<ProductCardDetailProps>> = ({ children, enabled, onDetailExpand, expanded, item = true, ...props }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(expanded)
  const handleAccordionChange = () => {
    setIsAccordionOpen(!isAccordionOpen)

    if (onDetailExpand) {
      onDetailExpand()
    }
  }

  useEffect(() => {
    setIsAccordionOpen(expanded)
  }, [expanded])

  return (
    <StyledProductCardDetailContainer item={item} {...props}>
      <Grid container direction={'column'} alignItems={'center'} justifyContent={'flex-start'}>
        {enabled && (
          <StyledAccordion onChange={handleAccordionChange} expanded={expanded}>
            <StyledAccordionSummary>
              <StyledAccordionTitleSpan>
                <StyledAccordionTitle>
                  {isAccordionOpen ? 'Show less detail' : 'Show more detail'}
                </StyledAccordionTitle>
                {isAccordionOpen ? (
                  <StyledAccordionTitleIcon icon={faMinus} />
                ) : (
                  <StyledAccordionTitleIcon icon={faPlus}/>
                )}
              </StyledAccordionTitleSpan>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              {children}
            </StyledAccordionDetails>
          </StyledAccordion>
        )}
        <StyledSectionHorizontalLine $showBorder={!isAccordionOpen || !enabled} />
      </Grid>
    </StyledProductCardDetailContainer>
  )
}
