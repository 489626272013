import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'


export const StyledWrapper = styled(Grid)`
  ${props => props.theme.breakpoints.up('md')} {
    min-height: 75vh;
  }
`

export const StyledList = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;

  ${props => props.theme.breakpoints.up('md')} {
    border-top: 3px solid rgba(77, 77, 77, 0.2);
    padding-top: ${props => props.theme.typography.pxToRem(32)};
    margin-left: ${props => props.theme.typography.pxToRem(56)};
  }
`

export const StyledItem = styled.li`
  font-size: ${props => props.theme.typography.pxToRem(16)};
  font-weight: 400;
  position: relative;
  padding-left: ${props => props.theme.typography.pxToRem(25)};
  margin-bottom: 0;
  margin-left: ${props => props.theme.typography.pxToRem(5)};
  line-height: ${props => props.theme.typography.pxToRem(16)};
  margin-top: ${props => props.theme.typography.pxToRem(10)};

  & > a {
    display: inline-block;
    line-height: ${props => props.theme.typography.pxToRem(26)};
    padding-bottom: ${props =>props.theme.typography.pxToRem(5)};
    padding-left: ${props => props.theme.typography.pxToRem(10)};
  }

  & > span {
    color: ${props => props.theme.palette.secondary.main};
    font-size: ${props => props.theme.typography.pxToRem(17)};
    position: absolute;
    top: 18px;
    left: 15px;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    margin-left: ${props => props.theme.typography.pxToRem(210)};
    line-height: ${props => props.theme.typography.pxToRem(16)};

    & > a {
      display: inline-block;
      line-height: ${props => props.theme.typography.pxToRem(26)};
      padding-bottom: ${props =>props.theme.typography.pxToRem(5)};
      padding-left: ${props => props.theme.typography.pxToRem(15)};
    }

    & > span {
      color: ${props => props.theme.palette.secondary.main};
      font-size: ${props => props.theme.typography.pxToRem(17)};
      position: absolute;
      top: 13px;
      left: 15px;
    }
  }

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: unset;
    margin-left: 0;

    & > a {
      display: inline-block;
      line-height: ${props => props.theme.typography.pxToRem(26)};
      padding-bottom: ${props =>props.theme.typography.pxToRem(5)};
      padding-left: ${props => props.theme.typography.pxToRem(10)};
    }

    & > span {
      top: 17px;
      left: -5px;
    }
  }

  &::before {
    content: "";
    padding: 0;
  }
`

export const ListWrapper = styled(Grid)`
  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => props.theme.typography.pxToRem(204)};
  }
`

export const StyledImage = styled.div`
  width: ${props => props.theme.typography.pxToRem(325)};
  height: ${props => props.theme.typography.pxToRem(146)};
  z-index: 1;

  ${props => props.theme.breakpoints.up('sm')} {
    width: ${props => props.theme.typography.pxToRem(342)};
    height: ${props => props.theme.typography.pxToRem(215)};
  }

  & > div > img {
    z-index: 100;
  }
`

export const StyledTriangle = styled.div`
  position: absolute;
  z-index: -1;
  width: ${props => props.theme.typography.pxToRem(100)};
  height: ${props => props.theme.typography.pxToRem(100)};
  right: -19px;
  top: -37px;

  ${props => props.theme.breakpoints.up('xs')} {
    width: ${props => props.theme.typography.pxToRem(130)};
    height: ${props => props.theme.typography.pxToRem(120)};
    right: -34px;
    top: -39px;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    width: ${props => props.theme.typography.pxToRem(240)};
    height: ${props => props.theme.typography.pxToRem(200)};
    right: -20%;
    top: -35%;
  }

  ${props => props.theme.breakpoints.up('md')} {
    width: ${props => props.theme.typography.pxToRem(290)};
    height: ${props => props.theme.typography.pxToRem(250)};
    top: unset;
    right: unset;
    left: -50%;
    bottom: -60%;
  }
`

export const ImageContainer = styled(Grid)`
  position: relative;
  margin-bottom: ${props => props.theme.typography.pxToRem(38)};

  ${props => props.theme.breakpoints.down('sm')} {
    height: ${props => props.theme.typography.pxToRem(200)};
  }
`

export const LinksTitle = styled(Typography)`
  margin-top: ${props => props.theme.typography.pxToRem(70)};
  margin-bottom: ${props => props.theme.typography.pxToRem(30)};
  font-size: ${props => props.theme.typography.pxToRem(23)};
  font-weight: 500;
  color: ${props => props.theme.palette.primary.dark};
  max-width: ${props => props.theme.typography.pxToRem(250)};
  text-align: center;
  margin-right: unset;

  ${props => props.theme.breakpoints.up('md')} {
    max-width: ${props => props.theme.typography.pxToRem(350)};
    text-align: left;
    font-size: ${props => props.theme.typography.pxToRem(32)};
  }
`

export const ListRow = styled(Grid)`
  margin-bottom: ${props => props.theme.typography.pxToRem(50)};
`

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.theme.typography.pxToRem(16)};
  line-height: ${props => props.theme.typography.pxToRem(32)};
  padding-top: ${props => props.theme.typography.pxToRem(10)};
  margin-left: ${props => props.theme.typography.pxToRem(10)};

  &:hover {
    color: ${props => props.theme.palette.secondary.main};
  }

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(19)};
    padding-top: ${props => props.theme.typography.pxToRem(20)};
  }
`
