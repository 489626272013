import Grid from '@material-ui/core/Grid'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { ProductCardGridWithPadding } from './styledProductCard'

export type ProductCardFooterProps = React.ComponentProps<typeof ProductCardGridWithPadding> & {
  //
}

export const ProductCardFooterContainer = styled(Grid)`
  ${props => props.theme.breakpoints.up('sm')} {
    padding-right: ${props => props.theme.typography.pxToRem(15)};
  }
`

export const ProductCardFooter: React.FC<PropsWithChildren<ProductCardFooterProps>> = ({ children, ...props }) => {
  return (
    <ProductCardGridWithPadding item { ...props }>
      <ProductCardFooterContainer container direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        { children }
      </ProductCardFooterContainer>
    </ProductCardGridWithPadding>
  )
}
