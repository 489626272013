import { gql } from '@apollo/client'
import React, { PropsWithChildren, useContext } from 'react'
import { IMAGE_FRAGMENT } from '../../../fragments/image'
import { SecondaryPageBanner } from '../../../fragments/secondaryPageBanner'
import { GlobalContext } from '../../../globals/context'
import { isNotNull } from '../../../utils/nonNull'
import { ProductBannerFragment } from './__generated__/secondaryBanner'

export type SecondaryBannerProps = {
  data: ProductBannerFragment
  breadcrumbs: React.ReactNode
}

export const ProductBanner: React.FC<PropsWithChildren<SecondaryBannerProps>> = ({ data, breadcrumbs }) => {
  const { fallback } = useContext(GlobalContext)

  if (!data || data.__typename !== 'secondaryPageBanner_BlockType' || fallback?.globalSet?.__typename !== 'siteSettings_GlobalSet') {
    return null
  }

  const { productListingImageFallback } = fallback.globalSet

  const { secondaryBannerTitle, secondaryBannerSubtext, secondaryBannerPrimaryButton, secondaryBannerSecondaryButton, secondaryBannerImage } = data

  const image = isNotNull(secondaryBannerImage) && secondaryBannerImage.length> 0 ? secondaryBannerImage: productListingImageFallback as typeof secondaryBannerImage

  return (
    <SecondaryPageBanner
      title={secondaryBannerTitle}
      subTitle={secondaryBannerSubtext}
      primaryButton={secondaryBannerPrimaryButton}
      secondaryButton={secondaryBannerSecondaryButton}
      image={image}
      breadcrumbs={breadcrumbs}
      after={'light'}
    />
  )
}

export const PRODUCT_LISTING_BANNER_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}

  fragment ProductBannerFragment on secondaryPageBanner_BlockType {
    secondaryBannerImage {
      ...ImageFragment
    }
    secondaryBannerSubtext
    secondaryBannerTitle
    secondaryBannerPrimaryButton {
      url
      customText
      target
    }
    secondaryBannerSecondaryButton {
      url
      customText
      target
    }
  }
`
