import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { rem } from '../../utils/rem'


export const StyledCallOutWrapper = styled(Grid)`
  margin-top: ${rem(100)};

  ${props => props.theme.breakpoints.down('md')} {
    margin-top: ${rem(86)};

    & > div:nth-child(2) > div:nth-child(1) {
      padding-top: 0;
    }
  }
`

export const StyledCallOutRow = styled(Grid)`
  column-gap: ${rem(63)};
  flex-direction: column;

  ${props => props.theme.breakpoints.up('md')} {
    flex-direction: row;
  }

`

export const StyledCallOutTitle = styled(Typography)`
  font-size: ${rem(23)};
  letter-spacing: 0px;
  text-transform: unset;
  margin-bottom: ${rem(49)};
  color: #1C1F2A;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${rem(32)};
    margin-bottom: ${rem(70)};
  }
`

export const StyledCardContainer = styled(Grid)`
  border-bottom: 5px solid #F2F2F2;
  padding-bottom: ${rem(35)};
  display: flex;
  flex-direction: row;
  column-gap: ${rem(21)};
  padding-top: ${rem(28)};


  ${props => props.theme.breakpoints.up('md')} {
    height: ${rem(290)};
    max-width: ${rem(407)};
    flex-direction: column;
    padding-top: 0;
  }
`

export const StyledImageContainer = styled.div`
  width: ${rem(35)};
  height: ${rem(35)};
  min-width: ${rem(35)};
  min-height: ${rem(35)};
  position: relative;

  ${props => props.theme.breakpoints.up('md')} {
    width: ${rem(57)};
    height: ${rem(57)};
    min-width: ${rem(57)};
    min-height: ${rem(57)};
  }
`

export const StyledContentColumn = styled(Grid)`
  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${rem(20)};
  }
`

export const StyledCallOutSubTitle = styled(Typography)`
  font-size: ${rem(20)};
  line-height: ${rem(34)};
  padding-top: ${rem(2.5)};
  margin-bottom: ${rem(13)};
  color: #1C1F2A;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${rem(24)};
    margin-bottom: ${rem(35)};
  }
`

export const StyledCallOutContent = styled(Typography)`
  font-size: ${rem(15)};
  line-height: ${rem(28)};
  color: #4D4D4D;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${rem(17)};
    line-height: ${rem(35)};
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    flex-direction: column;
    text-overflow: ellipsis;
    word-break: break-word;
  }
`