import Grid from '@material-ui/core/Grid'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { ProductCardGridWithPadding } from './styledProductCard'

export type ProductCardFeatureProps = React.ComponentProps<typeof Grid> & {
  //
}

export const CardFeatureHeader = styled.p`
  margin: 0 0 ${props => props.theme.typography.pxToRem(14)};
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.theme.typography.pxToRem(14)};
  font-weight: 500;
`

export const ProductCardFeature: React.FC<PropsWithChildren<ProductCardFeatureProps>> = ({ children, item=true, ...props }) => {
  return (
    <ProductCardGridWithPadding item={item} {...props}>
      <Grid container direction={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
        <Grid item>
          <CardFeatureHeader>Key features & benefits</CardFeatureHeader>
        </Grid>
        <Grid item>
          { children }
        </Grid>
      </Grid>
    </ProductCardGridWithPadding>
  )
}
