import styled from 'styled-components'
import { Button } from '@material-ui/core'

export const ProductFooterOutlinedButton = styled(Button)`
  border-color: #517f34;
  color: #517f34;
`
export const ProductFooterLinkButton = styled.a`
  padding: ${props => props.theme.typography.pxToRem(15)} ${props => props.theme.typography.pxToRem(20)};
  text-decoration: underline;
  font-size: ${props => props.theme.typography.pxToRem(15)};
  font-weight: 500;
  cursor: pointer;
  transition: color 0.15s ease-in-out;

  &:hover {
    color: ${props => props.theme.palette.secondary.dark};
  }

  ${props => props.theme.breakpoints.up('sm')} {
    padding: ${props => props.theme.typography.pxToRem(15)} ${props => props.theme.typography.pxToRem(30)};
  }
`
