import Grid from '@material-ui/core/Grid'
import React, { PropsWithChildren } from 'react'
import { ProductCardGridWithPadding } from './styledProductCard'
import {
  ProductCardDescription,
  ProductCardTitle,
  ProductTitleContainer,
  RateCard,
  RateCardDetails,
  RateCardName,
  RateCardNumber,
  RateCardPercentage,
  RateCardPercentageContainer,
  RateCardType,
} from './styledProductCardHeader'


export type ProductCardHeaderProps = React.ComponentProps<typeof Grid> & {
  heading: string
  rates?: ProductCardRateType
  href?: string
}

export type ProductCardRateType = {
  productPrimaryRateType?: string
  productPrimaryRate?: string
  productComparisonRateTitle?: string
  productComparisonRate?: string
  __typename?: string
}

const formatRatePercentage = (number?: string | null) => Number.isNaN(Number(number)) ? number : Number(number).toFixed(2)

export const ProductCardHeader: React.FC<PropsWithChildren<ProductCardHeaderProps>> = ({ heading, href, rates, children, ...props }) => {
  return (
    <ProductCardGridWithPadding item {...props} data-same-height>
      <Grid container direction={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
        <ProductTitleContainer item>
          <ProductCardTitle href={href}>
            {heading}
          </ProductCardTitle>
        </ProductTitleContainer>
        <Grid item>
          <Grid container direction={'row'} alignItems={'flex-start'} wrap={'nowrap'}>
            {
              rates &&
              <>
                <RateCard $hasMargin>
                  <RateCardName>{rates.productPrimaryRateType}</RateCardName>
                  <RateCardDetails>
                    <RateCardNumber>
                      {formatRatePercentage(rates.productPrimaryRate)}
                    </RateCardNumber>
                    <RateCardPercentageContainer>
                      <RateCardPercentage>%</RateCardPercentage>
                      <RateCardType>{'p.a'}</RateCardType>
                    </RateCardPercentageContainer>
                  </RateCardDetails>
                </RateCard>
                {rates.productComparisonRate &&
                  <RateCard>
                    <RateCardName>{rates.productComparisonRateTitle}</RateCardName>
                    <RateCardDetails>
                      <RateCardNumber>
                        {formatRatePercentage(rates.productComparisonRate)}
                      </RateCardNumber>
                      <RateCardPercentageContainer>
                        <RateCardPercentage>%</RateCardPercentage>
                        <RateCardType>{'p.a'}</RateCardType>
                      </RateCardPercentageContainer>
                    </RateCardDetails>
                  </RateCard>}
              </>
            }
          </Grid>
          <Grid item>
            <ProductCardDescription>
              {children}
            </ProductCardDescription>
          </Grid>
        </Grid>
      </Grid>
    </ProductCardGridWithPadding>
  )
}
