import { gql } from '@apollo/client'
import React, { PropsWithChildren } from 'react'
import { ProductCallOutFragment } from './__generated__/callOut'
import { StyledCallOutRow, StyledCallOutWrapper, StyledCallOutTitle, StyledCardContainer, StyledCallOutSubTitle, StyledCallOutContent, StyledImageContainer, StyledContentColumn } from '../../../components/callOut/styledCallOut'
import Image from 'next/image'
import { isNotNull } from '../../../utils/nonNull'

export type CallOutProps = {
  title?: string
  cards?: ProductCallOutFragment[]
}

export const CallOut: React.FC<PropsWithChildren<CallOutProps>> = ({ title, cards }) => (
  <StyledCallOutWrapper
    direction="column"
    justifyContent="center"
    alignItems="center"
    wrap="nowrap"
    container
  >
    {isNotNull(title) && (
      <StyledCallOutRow 
        container 
        item xs={12} sm={12}
        direction="row"
        justifyContent="center"
        alignItems="center"
        wrap="nowrap"
      >
        <StyledCallOutTitle variant="h5">{title}</StyledCallOutTitle>
      </StyledCallOutRow>
    )}
    <StyledCallOutRow
      container 
      item xs={12} sm={12}
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      wrap="nowrap"
    >
      {cards?.map(({ columnTitle, columnContent }, idx) => (
        <StyledCardContainer
          item xs={12} sm={12}
          key={idx}
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          wrap="nowrap"
        >
          <StyledImageContainer>
            <Image
              layout="fill"
              objectFit="contain"
              src="/images/tickIcon.svg"
              alt="tick"
            />
          </StyledImageContainer>
          <StyledContentColumn
            item xs={12} sm={12}
            key={idx}
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            wrap="nowrap"
          >
            <StyledCallOutSubTitle variant="h6">{columnTitle}</StyledCallOutSubTitle>
            <StyledCallOutContent>{columnContent}</StyledCallOutContent>
          </StyledContentColumn>
        </StyledCardContainer>
      ))}
    </StyledCallOutRow>
  </StyledCallOutWrapper>
)

export const CALL_OUT_FRAGMENT = gql`
  fragment ProductCallOutFragment on columnCallOutCards_BlockType {
    id
    columnTitle
    columnContent
  }
`
