import { gql } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import React, { PropsWithChildren, useCallback, useState } from 'react'
import { TabHeader, TabsPropsFixed } from '../../../components/tabs/tabHeader'
import { TabPanel } from '../../../components/tabs/tabPanel'
import { TabWrapper } from '../../../components/tabs/tabWrapper'
import { HeaderTabs } from '../../../components/tabs/tabs'
import { FLEXIBLE_CONTENT_BASE_SPACING } from '../../../constants/flexibleContentBaseSpacing'
import { html } from '../../../utils/htmlParser'
import { TabsModuleFragment } from './__generated__/tabsModule'

export type TabsModuleProps = {
  data: TabsModuleFragment[]
}

export const TabsModule: React.FC<PropsWithChildren<TabsModuleProps>> = ({ data }) => {
  const numberofTabs = data.length
  const [selectedTab, setSelectedTab] = useState(0)
  const tabItems = data.slice(0,numberofTabs)
  const onChange = useCallback(
    (_, newValue) => setSelectedTab(newValue),
    [setSelectedTab],
  ) as TabsPropsFixed['onChange']

  return (
    <TabWrapper>
      <TabHeader value={selectedTab} onChange={onChange} variant={'scrollable'}>
        {
          tabItems.map((item, i) => {return item.__typename !== 'tabsModule_BlockType' ? null : (
            <HeaderTabs key={i} label={item.tabTitle}/>
          )
          })
        }
      </TabHeader>
      {
        tabItems.map((item, i ) => {return item.__typename !== 'tabsModule_BlockType' ? null : (
          <TabPanel key={i} index={selectedTab} value={i}>
            <Grid container direction="row-reverse" justifyContent="center" alignItems="flex-start">
              {item.tabContent?.[1] ?
                <Grid container spacing={FLEXIBLE_CONTENT_BASE_SPACING}>
                  <Grid item xs={12} md={6}>
                    {item.tabContent?.[0]?.columnContent && html(item.tabContent[0].columnContent)}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {item.tabContent[1]?.columnContent && html(item.tabContent[1].columnContent)}
                  </Grid>
                </Grid>
                :
                <> 
                  <Grid>
                    {item.tabContent?.[0]?.columnContent && html(item.tabContent[0].columnContent)}
                  </Grid>
                </>
              }
            </Grid>
          </TabPanel>
        )
        })
      }
    </TabWrapper>
  )
}

export const TABS_FRAGMENT = gql`
  fragment TabsModuleFragment on tabsModule_BlockType {
    tabTitle
    tabContent {
      ... on tabContent_column_BlockType {
        columnContent
      }
    }
  }
`
