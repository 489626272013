import { alpha } from '@material-ui/core/styles'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

const StyledSpacer = styled.div<{$height: number}>`
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: ${props => props.theme.typography.pxToRem(props.$height)};
  margin-bottom: 0 !important;
`

const StyledHr = styled.hr`
  flex: 1;
  height: ${props => props.theme.typography.pxToRem(3)};
  background-color: ${props => alpha(props.theme.palette.text.secondary, 0.19)};
  border: none;
`

export type SpacerProps = {
  hrSpacer?: boolean
  height?: number
} & React.ComponentProps<typeof StyledSpacer>

export const Spacer: React.FC<PropsWithChildren<SpacerProps>> = ({ hrSpacer, height, ...props }) => (
  <StyledSpacer {...({ $height : height })} {...props}> {
    hrSpacer ? <StyledHr /> : null
  }
  </StyledSpacer>
)