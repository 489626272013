import { gql } from '@apollo/client'
import React, { PropsWithChildren } from 'react'
import { QuickLinks as QuickLinksBase } from '../../../components/QuickLinksProductListing/QuickLinks'
import { ProductQuickLinksFragment } from './__generated__/quickLinks'

export type QuickLinksProps = {
  data: ProductQuickLinksFragment[]
}

export const QuickLinks: React.FC<PropsWithChildren<QuickLinksProps>> = ({ data }) => (
  <QuickLinksBase justifyContent={data.length > 2 ? 'space-between' : 'flex-start'}>
    {data.map((item, index) => {return item.__typename !== 'quickLinksProducts_quickLinks_BlockType' ? null : (
    // @ts-ignore
      <QuickLinksBase key={index} card iconCode={item.linkIcon} title={item.text} href={item.iconUrl.url} sm={2} xs={12} target={item.iconUrl.target === '_blank' ? '_blank' : '_self'}/>
    )})}
  </QuickLinksBase>
)

export const QUICK_LINKS_FRAGMENT = gql`
  fragment ProductQuickLinksFragment on quickLinksProducts_quickLinks_BlockType {
    text
    linkIcon
    iconUrl {
      url
      target
    }
  }
`
