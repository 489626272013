import styled from 'styled-components'
import { Icon } from '../icon/icon'

export const ProductCardFeatureSpan = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: ${props => props.theme.typography.pxToRem(10)};
`

export const ProductCardIcon = styled(Icon)`
  position: relative;
  top: 2px;
  font-size: ${props => props.theme.typography.pxToRem(16)};
  color: ${props => props.theme.palette.primary.main};
  margin-right: ${props => props.theme.typography.pxToRem(6)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-right: ${props => props.theme.typography.pxToRem(6)};
    font-size: ${props => props.theme.typography.pxToRem(17)};
  }
`

export const ProductCardFeatureText = styled.p`
  margin: 0;
  color: ${props => props.theme.palette.text.secondary};
  font-size: ${props => props.theme.typography.pxToRem(16)};
  font-weight: 500;
  line-height: 1.5;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(17)};
  }
`
