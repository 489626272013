import { gql } from '@apollo/client'
import React, { PropsWithChildren } from 'react'
import { InfoLinks as InfoLinksBase } from '../../../components/furtherInfoLinks/furtherInfoLinks'
import { IMAGE_FRAGMENT } from '../../../fragments/image'
import { nonNull } from '../../../utils/nonNull'
import { InfoLinksFragment } from './__generated__/infoLinks'

export type InfoLinksProps = {
  data: InfoLinksFragment[]
}

export const InfoLinks: React.FC<PropsWithChildren<InfoLinksProps>> = ({ data }) => {
  if (!data[0] || data[0].__typename !== 'informationLinks_BlockType') {
    return null
  }

  const { infoLinkstitle, leftLinksList, rightLinks, image } = data[0]

  if (!image?.[0]?.url) {
    return null
  }

  return (
    <InfoLinksBase image={image[0].url} title={infoLinkstitle}>
      {leftLinksList && (
        <InfoLinksBase list>
          {nonNull(leftLinksList).map((link, i) => {return link.__typename !== 'leftLinksList_infoLink_BlockType' ? null : (
            <InfoLinksBase key={i} item href={link?.linkField?.url || '#'}>{link?.linkField?.customText}</InfoLinksBase>
          )})}
        </InfoLinksBase>
      )}

      {rightLinks && (
        <InfoLinksBase list>
          {nonNull(rightLinks).map((link, i) => {return link.__typename !== 'rightLinks_infoLink_BlockType' ? null : (
            <InfoLinksBase key={i} item href={link?.linkField?.url || '#'}>{link?.linkField?.customText}</InfoLinksBase>
          )})}
        </InfoLinksBase>
      )}
    </InfoLinksBase>
  )
}

export const INFO_LINKS_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}

  fragment InfoLinksFragment on informationLinks_BlockType {
    id
    infoLinkstitle
    image {
      ...ImageFragment
    }
    rightLinks {
      ... on rightLinks_infoLink_BlockType {
        linkField {
          target
          url
          customText
        }
      }
    }
    leftLinksList {
      ... on leftLinksList_infoLink_BlockType {
        linkField {
          target
          url
          customText
        }
      }
    }
  }
`
