import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import Grid from '@material-ui/core/Grid'
import React, { PropsWithChildren } from 'react'
import { CreditUnionIcon } from '../icon/CreditUnionIcon'
import { QuickLinksCardProps } from './QuickLinksCard'
import { StyledAccordion, StyledAccordionSummary, StyledChevron } from './styledAccordionCard'
import { StyledCardContainer, StyledTitleGridWrapper, StyledTitleText } from './styledQuickLinksCard'

export const QuickLinksAccordianCard: React.FC<PropsWithChildren<QuickLinksCardProps>> =  ({ title, iconCode, href, children, ...props }) => {
  return (
    <StyledCardContainer item xs={12} sm={4} {...props}>
      <StyledAccordion href={href}>
        <StyledAccordionSummary>
          <Grid container spacing={1} alignItems="center" justifyContent="space-between" wrap="nowrap">
            <Grid item>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center" wrap="nowrap">
                <Grid item>
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <CreditUnionIcon width={33} height={33} iconCode={iconCode}/>
                  </Grid>
                </Grid>
                <StyledTitleGridWrapper item>
                  <StyledTitleText>{title}</StyledTitleText>
                </StyledTitleGridWrapper>
              </Grid>
            </Grid>
          </Grid>
          <StyledChevron icon={faChevronRight}/>
        </StyledAccordionSummary>
      </StyledAccordion>
    </StyledCardContainer>
  )
}