/* eslint-disable unicorn/filename-case */
import { gql } from '@apollo/client'
import React, { PropsWithChildren } from 'react'
import { ToolCta as CTA } from '../../../components/toolcta/ToolCta'
import { html } from '../../../utils/htmlParser'
import { isNotNull } from '../../../utils/nonNull'
import { TwoColCtaFragment } from './__generated__/twoColCTA'

export type TwoColCtaProps = {
  data: TwoColCtaFragment[]
}

export const TwoColCTA: React.FC<PropsWithChildren<TwoColCtaProps>> = ({ data }) => {
  if (!data[0] || data[0].__typename !== 'twoColumnCta_BlockType') {
    return null
  }

  const { twoColRightCtaTitle, twoColRightCtaSubtext, rightColumnCtaButton, twoColLeftCtaTitle, twoColLeftCtaSubtext, leftColumnCtaButton } = data[0]
  const hasLeftColumn = Boolean(twoColLeftCtaTitle || twoColLeftCtaSubtext || leftColumnCtaButton)
  const hasRightColumn = Boolean(twoColRightCtaTitle || twoColRightCtaSubtext || rightColumnCtaButton)

  return (
    <CTA>
      {hasLeftColumn && (
        <CTA item>
          {twoColLeftCtaTitle && (
            <CTA title>{twoColLeftCtaTitle}</CTA>
          )}
          {twoColLeftCtaSubtext && (
            <CTA subText>{html(twoColLeftCtaSubtext)}</CTA>
          )}
          {leftColumnCtaButton && leftColumnCtaButton.__typename === 'linkField_Link' && isNotNull(leftColumnCtaButton.url) && isNotNull(leftColumnCtaButton.customText) && (
            <CTA cta href={leftColumnCtaButton.url} target={leftColumnCtaButton.target === '_blank' ? '_blank' : '_self'}>{leftColumnCtaButton.customText}</CTA>
          )}
        </CTA>
      )}
      {hasRightColumn && (
        <CTA item>
          {twoColRightCtaTitle && (
            <CTA title>{twoColRightCtaTitle}</CTA>
          )}
          {twoColRightCtaSubtext && (
            <CTA subText>{html(twoColRightCtaSubtext)}</CTA>
          )}
          {rightColumnCtaButton && rightColumnCtaButton.__typename === 'linkField_Link' && isNotNull(rightColumnCtaButton.url) && isNotNull(rightColumnCtaButton.customText) && (
            <CTA cta href={rightColumnCtaButton.url} target={rightColumnCtaButton.target === '_blank' ? '_blank' : '_self'}>{rightColumnCtaButton.customText}</CTA>
          )}
        </CTA>
      )}
    </CTA>
  )
}

export const TWO_COL_CTA_FRAGMENT = gql`
  fragment TwoColCTAFragment on twoColumnCta_BlockType {
    twoColRightCtaTitle
    twoColRightCtaSubtext
    twoColLeftCtaTitle
    twoColLeftCtaSubtext
    leftColumnCtaButton {
      url
      customText
      target
    }
    rightColumnCtaButton {
      url
      target
      customText
    }
  }
`
