import React from 'react'
import { ComparisonCtaCard, ComparisonCtaCardProps } from './ComparisonCtaCard'
import { ComparisonCtaItem, ComparisonCtaItemProps } from './ComparisonCtaItem'
import { ComparisonCtaWrapper, ComparisonCtaWrapperProps } from './ComparisonCtaWrapper'
import { ComparisonCtaAccordianCard } from './ComparisonCtaAccordionCard'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export type ComparisonCtaProps = (
    | ({ card: true; item?: false } & ComparisonCtaCardProps)
    | ({ card?: false; item: true } & ComparisonCtaItemProps)
    | ({ card?: false; item?: false } & ComparisonCtaWrapperProps)
    )

//TO DO: Remove `as type` with TypeScript Magic
export const ComparisonCta: React.VFC<ComparisonCtaProps> = ({ card, item, ...props }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

    if (item) {
        return <ComparisonCtaItem { ...props as ComparisonCtaItemProps} />
    }

    if (card) {
        return (isMobile) ?
            (<ComparisonCtaAccordianCard { ...props as ComparisonCtaCardProps} />) :
            (<ComparisonCtaCard { ...props as ComparisonCtaCardProps} />)
    }

    return <ComparisonCtaWrapper { ...props as ComparisonCtaWrapperProps} />
}