import { gql } from '@apollo/client'
import React, { PropsWithChildren } from 'react'
import { OfferPromotionCarousel } from '../../../components/OfferPromotionCarousel/OfferPromotionCarousel'
import { IMAGE_FRAGMENT } from '../../../fragments/image'
import { html } from '../../../utils/htmlParser'
import { CarouselFragment } from './__generated__/offerPromotions'

export type OfferPromotionsProps = {
  data: CarouselFragment[]
}

export const OfferPromotions: React.FC<PropsWithChildren<OfferPromotionsProps>> = ({ data }) => (
  <OfferPromotionCarousel>
    {
      data.map((item, index) => {  return item.__typename !== 'offersCarousel_BlockType' ? null : (
        <OfferPromotionCarousel flickitySection key={index} variant={index <= 3 ? 'offer' : 'promotion'}>
          {item?.carouselItemImage?.[0]?.url && (
            <OfferPromotionCarousel image src={item.carouselItemImage[0].url} alt="" layout="fill" objectFit="cover" objectPosition="center" />
          )}
          <OfferPromotionCarousel contentSection>
            <OfferPromotionCarousel title>{item.carouselItemTitle}</OfferPromotionCarousel>
            {item.carouselItemSubtext && <OfferPromotionCarousel description>{html(item.carouselItemSubtext)}</OfferPromotionCarousel>}
            {item.carouselButtonText && <OfferPromotionCarousel href={item?.carouselButtonLink?.url || '#'} {...item?.carouselButtonLink?.target === '_blank' && { target: '_blank' }} cta primary buttonColor="black">
              {item.carouselButtonText}
            </OfferPromotionCarousel>}
          </OfferPromotionCarousel>
        </OfferPromotionCarousel>
      )})
    }
  </OfferPromotionCarousel>
)

export const CAROUSEL_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}

fragment CarouselFragment on offersCarousel_BlockType {
  id
  carouselItemTitle
  carouselItemSubtext
  carouselButtonText
  carouselButtonLink {
    url
    target
  }
  carouselItemImage {
    ...ImageFragment
  }
}
`
