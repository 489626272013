import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'

export const ProductCardGridWithPadding = styled(Grid)`
  padding-left: ${props => props.theme.typography.pxToRem(37)};
  padding-right: ${props => props.theme.typography.pxToRem(20)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding-left: ${props => props.theme.typography.pxToRem(52)};
    padding-right: ${props => props.theme.typography.pxToRem(47)};
  }
`