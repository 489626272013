import React, { PropsWithChildren } from 'react'
import { CreditUnionIcon, IconCodeType } from '../icon/CreditUnionIcon'
import { LinkProps } from '../link/link'
import {
    StyledCardContainer,
    StyledCardIconContainer,
    StyledCardWrapper,
    StyledQuickLink,
    StyledTitleText,
    StyledCardCtaButton,
    StyledCtaButtonWrapper
} from './styledComparisonCtaCard'


export type ComparisonCtaCardProps =  React.ComponentProps<typeof StyledCardContainer> & Pick<LinkProps, 'href'> & {
    title: string
    iconCode: IconCodeType
    href?: string
    target?: string
    buttonText?: string
}

export const ComparisonCtaCard: React.FC<PropsWithChildren<ComparisonCtaCardProps>> = ({ buttonText, title, iconCode, href, target, children, item=true, xs=12, sm=12, ...props }) => {
    return (
        <StyledCardContainer item={item} xl={3} lg={3} xs={xs} sm={sm} {...props}>
            <StyledCardWrapper container direction="column" justifyContent="flex-start" alignItems="flex-start">
                <StyledCardIconContainer item>
                    {/*<StyledTitleText aria-label={title} href={href ?? '#'} rel={target === '_blank' ? 'nooppener noreferrer' : ''} target={target ?? '_self'}>*/}
                    <CreditUnionIcon alt={`${iconCode} icon`} width={49} height={49} iconCode={iconCode}/>
                    {/*</StyledTitleText>*/}
                </StyledCardIconContainer>
                <StyledQuickLink container direction="row" justifyContent="flex-start" alignItems="flex-start" wrap="nowrap">
                    <StyledTitleText>{ title }</StyledTitleText>
                </StyledQuickLink>
            </StyledCardWrapper>
            <StyledCtaButtonWrapper container>
                <StyledCardCtaButton text aria-label={buttonText} href={href ?? '#'} target={target ?? '_self'}>{buttonText ?? 'Learn more'}</StyledCardCtaButton>
            </StyledCtaButtonWrapper>
        </StyledCardContainer>
    )
}