import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { irem } from '../../utils'

export const ProductCardHeaderGrid = styled(Grid)`
  padding-left: ${props => props.theme.typography.pxToRem(37)};
  padding-right: ${props => props.theme.typography.pxToRem(20)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding-left: ${props => props.theme.typography.pxToRem(52)};
    padding-right: ${props => props.theme.typography.pxToRem(47)};
  }
`

export const ProductCardTitle = styled.a`
  color: ${props => props.theme.palette.primary.main};
  height: ${props => props.theme.typography.pxToRem(43)};
  margin-bottom: ${props => props.theme.typography.pxToRem(30)};
  font-size: ${props => props.theme.typography.pxToRem(20)};
  font-weight: 500;
  text-decoration: none;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(22)};
    height: ${props => props.theme.typography.pxToRem(62)};
  }
`

export const ProductTitleContainer = styled(Grid)`
  margin-bottom: 22px;
`

export const ProductCardDescription = styled.p`
  color: ${props => props.theme.palette.text.secondary};
  font-size: ${props => props.theme.typography.pxToRem(15)};
  margin-top: ${irem(24)};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(17)};
  }
`

export const RateCard = styled.div<{$hasMargin?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: ${props => props.theme.typography.pxToRem(112)};
  margin-right: ${props => props.$hasMargin ? props.theme.typography.pxToRem(28) : 0};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-right: ${props => props.$hasMargin ? props.theme.typography.pxToRem(61) : 0};
  }
`

export const RateCardName = styled.p`
  margin: 0;
  height: ${props => props.theme.typography.pxToRem(55)};
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.theme.typography.pxToRem(13)};
  font-weight: 500;
  line-height: ${irem(16)};
`

export const RateCardDetails = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
`

export const RateCardNumber = styled.p`
  margin: 0;
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.theme.typography.pxToRem(42)};
  line-height: 1.3;

`

export const RateCardPercentageContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${props => props.theme.palette.text.secondary};
  line-height: 1.3;
`

export const RateCardPercentage = styled.p`
  margin: 0;
  padding: 0;
  font-size: ${props => props.theme.typography.pxToRem(26)};
  transform: translateY(${props => props.theme.typography.pxToRem(4)});
`

export const RateCardType = styled.p`
  margin: 0;
  padding: 0;
  font-size: ${props => props.theme.typography.pxToRem(12)};
  transform: translateY(-${props => props.theme.typography.pxToRem(6)});
`
