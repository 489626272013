import styled from 'styled-components'
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core'
import { Icon } from '../icon/icon'
import { irem } from '../../utils'

export const StyledProductCardDetailContainer = styled(Grid)`
  width: 100%;
  margin-top: ${props => props.theme.typography.pxToRem(8)};
  padding-left: ${props => props.theme.typography.pxToRem(15)};
  margin-bottom: auto;

  ${props => props.theme.breakpoints.up('sm')} {
    padding-left: ${props => props.theme.typography.pxToRem(52)};
    padding-right: ${props => props.theme.typography.pxToRem(47)};
  }

  .MuiAccordion-root {
    width: 100%;
  }
`

export const StyledAccordion = styled(Accordion)`
  box-shadow: none;
`

export const StyledAccordionTitleSpan = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${props => props.theme.typography.pxToRem(22)};
  padding-top: ${props => props.theme.typography.pxToRem(15)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-left: 0;
  }
`

export const StyledAccordionTitle = styled.p`
  color: ${props => props.theme.palette.text.secondary};
  font-weight: 500;
  font-size: ${props => props.theme.typography.pxToRem(17)};
  padding-right: ${props => props.theme.typography.pxToRem(6)};
  margin: 0;
  text-decoration: underline;
`

export const StyledAccordionTitleIcon = styled(Icon)`
  color: ${props => props.theme.palette.primary.main};
  font-size: ${props => props.theme.typography.pxToRem(18)};
  transition: all 0.2s ease-out;
`

export const StyledAccordionSummary = styled(AccordionSummary)`
  transition: border-color 0.1s ease-in;
  padding: 0 0 ${irem(15)};

  &:hover {
    ${StyledAccordionTitle} {
      color: ${props => props.theme.palette.secondary.dark};
    }

    ${StyledAccordionTitleIcon} {
      transform: translateX(${props => props.theme.typography.pxToRem(10)});
    }
  }
`

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0 ${props => props.theme.spacing(1)} 0 0;

  ${props => props.theme.breakpoints.up('sm')} {
    padding: 0;
  }
`

export const StyledSectionHorizontalLine = styled.hr<{$showBorder?: boolean}>`
  margin-top: ${props => props.$showBorder ? props.theme.typography.pxToRem(16) : 0};
  transform: translateX(-${props => props.theme.typography.pxToRem(15)});
  width: ${props => props.$showBorder ? '80%' : '0'};

  ${props => props.theme.breakpoints.up('sm')} {
    transform: unset;
    margin-top: ${props => props.$showBorder ? props.theme.typography.pxToRem(12) : 0};
    width: ${props => props.$showBorder ? '100%' : '0'};
    margin-bottom: ${irem(32)};
  }
`